import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { MatDialog } from '@angular/material/dialog';
import { MicevideoComponent } from '../mice/dialog/micevideo/micevideo.component';

@Component({
  selector: 'app-mice',
  templateUrl: './mice.component.html',
  styleUrls: ['./mice.component.css']
})
export class MiceComponent implements OnInit {
  panelOpenState = false;
  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
  @ViewChild('nav1', {read: DragScrollComponent}) ds1: DragScrollComponent;
  constructor(
    private dialog: MatDialog) { }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }
  ngOnInit(): void {
  }
  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  ngAfterViewInit() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      this.ds.moveTo(0);
    }, 0);
  }
  moveLeft1() {
    this.ds1.moveLeft();
  }

  moveRight1() {
    this.ds1.moveRight();
  }

  moveTo1(index) {
    this.ds1.moveTo(index);
  }
  
  ngAfterViewInit1() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      this.ds1.moveTo(0);
    }, 0);
  }

  openVideoDialog(){
    this.dialog.open(MicevideoComponent, {panelClass: 'vpopup', width: '680px'});
  }
}
