import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment.prod';
export interface Section {
  img: string;
  name: string;
  route: string;
}
@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = [];
  showSubSubMenu: boolean = false;
  panelOpenState = false;
  contact_details = environment;

  mouseenter(isExpanded, i) {
    if (!isExpanded) {
      this.isShowing[i] = true;
    }else{
      this.isShowing[i] = false;
    }
  }

  links: Section[] = [
    {
      img: 'holidays',
      name: 'Holidays',
      route: '/',
    }
    // {
    //   img: 'hotels',
    //   name: 'Hotels',
    //   route: '/hotel',
    // },
    // {
    //   img: 'flights',
    //   name: 'Flights',
    //   route: '/flight',
    // }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
