import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './utils/header/header.component';
import { FooterComponent } from './utils/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharingModuleModule } from './sharing-module/sharing-module.module';
import { SidenavListComponent } from './utils/sidenav-list/sidenav-list.component';
import { LogInDialogComponent } from './utils/log-in-dialog/log-in-dialog.component';
import { ScrollTopComponent } from './utils/scroll-top/scroll-top.component';
import { ToursModule } from './tours/tours.module';
import { FourZeroFourComponent } from './utils/four-zero-four/four-zero-four.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchBarComponent } from './utils/search-bar/search-bar.component';
import { ForgetPasswordComponent } from './utils/forget-password/forget-password.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { MarkupComponent } from './admin/package/markup/markup.component';
import { MiceComponent } from './landing-pages/mice/mice.component';


@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidenavListComponent,
    LogInDialogComponent,
    ScrollTopComponent,
    FourZeroFourComponent,
    SearchBarComponent,
    ForgetPasswordComponent,
    MarkupComponent,
    MiceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharingModuleModule,
    // ToursModule,
    ReactiveFormsModule,
    FormsModule,
    SocialLoginModule
  ],
  entryComponents: [LogInDialogComponent],
  providers: [
    MatDatepickerModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '799233177051-4js0gb9di4mh4sei5vorvpa0l3b34toj.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1219233041450268')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
