<main id="content">
    <!-- ========== HERO ========== -->
    <div class="hero-block hero-v1 bannerBg bg-img-hero-bottom gradient-overlay-half-black-gradient text-center z-index-2 homeBg">
        <div class="container bannerContent space-2 space-top-xl-9 space-top-pd-8">
            <div class="content justify-content-md-center pb-xl-2__5">
                <!-- Info -->
                <div class=" pt-2 pb-2">
                    <div class="title font-size-60 font-size-xs-30 text-white font-weight-bold">Let our Experience be<br> 
                        Your Guide</div>
                        <!-- Book Hotels on trip<span class="text-pcolor">zolo</span> !  <small class="tagline">Escape To Explore</small></h1> -->
                    <!-- <p class="font-size-20 text-lh-1dot4 font-weight-normal text-white">We always make our customer happy by providing<br>
                        as many choices as possible.</p> -->
                </div>
                <!-- <div class="tp-parallax-wrap flyingPlane" style="position: absolute; visibility: visible;left:522px;top: 138px; z-index: 12;">
                    <img src="assets/img/plane.png" alt="" data-ww="auto" data-hh="auto" data-no-retina="" style="width: 313px; height: 117px; transition: none 0s ease 0s; line-height: 0px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 400; font-size: 12px;">
                </div> -->
                <!-- End Info -->
            </div>
        </div>
        <!-- <div class="shape-bottom">
            <img src="../../../assets/img/curve-shape-top.svg" alt="shape" class="bottom-shape img-fluid">
        </div> -->
    </div>
    <!-- ========== END HERO ========== -->
    <section class="topsection">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-12 col-12">
                    <div class="card sidebox border-0 tab-shadow">
                        <div class="card-body text-center">
                            <span class="topHeading text-lh-1dot4">We have a<br> <b>strategy for serving things right...</b></span>
                            <p class="font-size-14">We always make our customer happy by providing as many choices as possible.
                                Overall, the main goal of serving things at conferences is to ensure that attendees are well-fed, satisfied, and that they have a positive overall experience. By planning ahead, providing a variety of options, keeping it simple, focusing on quality, keeping attendees informed, staffing well and listening to feedback, you will be well on your way to serving things right at your conference! </p>
                            <div class="mt-4">
                                <button mat-button class="whiteBtn">Get Quote</button>
                            </div>
                        </div>
                        <div class="overlay"></div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="pt-4">
                        <h1 class="font-size-32 font-size-xs-30 text-black font-weight-bold">TRIPZOLO for MICE</h1>
                        <h2 class="subTitle text-lh-1 font-weight-normal text-black"><mat-icon class="quote-left">format_quote</mat-icon> Expanding horizons, connecting the world and Exotic locations for your meetings and conferences Worldwide ! <mat-icon>format_quote</mat-icon></h2>
                        <p>Tripzolo India is a reputed and full-service travel management company specializing in leisure and corporate travel with a personal touch with an emphasis on customized and tailor-made travel products, and experiences based on clients' expectations and comfort. All our vacation packages are customized to offer first-class experiences with insider access, authentic interactions, and upgrades at every step of the way.</p>
                        <p class="mb-0">With a primary business of Travel Management Services in India across seven continents, TripZolo houses a dedicated team of professionals in the department of MICE for Events and Conferences Management offering creative and state-of-the-art services from the past 10 years to leading corporates & Leisure Events. TripZolo offers customized and innovative services in Corporate Meetings inclusive of single-day events, Incentive programs like awards ceremonies, employee loyalty programs, Conferences either panel discussions, presentations, or workshops, Organize exhibitions like product launches promotions, networking, etc. Along with Corporate Events, we also professionally manage the Leisure events like concerts, including Artist management.</p>
                    </div>
                </div> 
            </div>
        </div>
    </section>
    <!-- <section class="tabs-block pt-5 pb-3">
        <div class="container">
           <div class="flex-center-between">
             <div class="sectionTitle pb-2">
                <h2 class="font-weight-bold text-black mb-2 text-lh-sm headingTitle text-left">Our Case Studies</h2>
               </div>   
                <a 
                   class="text-primary textS">View All
                <i class="flaticon-right-arrow ml-2"></i>
                </a>
             </div>
             <div class="position-relative">
                 <div class="d-flex flex-center-between overflowY tours-items">
                    <div class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                       <a target="_blank">
                          <div class="cardItem up h-100">
                             <div class="position-relative">
                                <a class="d-block nobbr">
                                    <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/hotels.jpg" alt="img">
                                </a>
                             </div>
                             <div class="card-body pcontent px-3 py-2">
                                <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Hotels</a>
                                <div>
                                    <span class="font-size-14 text-color">873,295 hotels</span>
                                </div>
                             </div>
                          </div>
                       </a>
                    </div>
                    <div class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                        <a target="_blank">
                           <div class="cardItem up h-100">
                              <div class="position-relative">
                                 <a class="d-block nobbr">
                                     <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/resorts.jpg" alt="img">
                                 </a>
                              </div>
                              <div class="card-body pcontent px-3 py-2">
                                 <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Resorts</a>
                                 <div>
                                    <span class="font-size-14 text-color">17,946 resorts</span>
                                 </div>
                              </div>
                           </div>
                        </a>
                     </div>
                     <div class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                        <a target="_blank">
                           <div class="cardItem up h-100">
                              <div class="position-relative">
                                 <a class="d-block nobbr">
                                     <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/villas.jpg" alt="img">
                                 </a>
                              </div>
                              <div class="card-body pcontent px-3 py-2">
                                 <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Villas</a>
                                 <div>
                                    <span class="font-size-14 text-color">466,217 villas</span>
                                 </div>
                              </div>
                           </div>
                        </a>
                     </div>
                     <div class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                        <a target="_blank">
                           <div class="cardItem up h-100">
                              <div class="position-relative">
                                 <a class="d-block nobbr">
                                     <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/apartments.jpg" alt="img">
                                 </a>
                              </div>
                              <div class="card-body pcontent px-3 py-2">
                                 <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Apartments</a>
                                 <div>
                                    <span class="font-size-14 text-color">889,155 apartments</span>
                                 </div>
                              </div>
                           </div>
                        </a>
                     </div>
                </div>
              </div>
        </div>
     </section> -->
     <!-- <div class="row mt-4">
        <div class="col-md-12 text-center">
           <button mat-raised-button color="accent" class="pBtn">More Packages</button>
        </div>
     </div> -->
    <section class="py-5 mx-auto">
        <div class="container">
            <div class="row">
                <div class="col-md-7 mb-3">
                    <h2 class="font-weight-bold text-black headingTitle font-size-xs-20 font-size-24 text-lh-sm">We serve you the Best</h2>
                    <!-- <h3 class="font-weight-bold font-size-24"><span class="bigLetter">B</span>ook Hotels with TRIP<span class="dark-pink">ZOLO</span></h3> -->
                    <p class="text-color mb-3">The services that are offered, such as event planning and coordination, transportation and accommodation, as well as information on venues and destinations. It could also include case studies or testimonials from past clients, and a call-to-action encouraging individuals or organizations to contact the company to plan their next MICE event.</p>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <div class="feature-box-1 services">
                                <div class="icon bgc-primary">
                                    <span class="text-white">1</span>
                                </div>
                                <div class="feature-content">
                                    <h5 class="feature-title mb-0">Destination Accessibility</h5>
                                    <p>Our dedicated team is expertise to manage all travel requirements with available options like Flights, Ships,  Train including local transport.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <div class="feature-box-1 services">
                                <div class="icon bgc-primary">
                                    <span class="text-white">2</span>
                                </div>
                                <div class="feature-content">
                                    <h5 class="feature-title mb-0">Hotel & Resorts</h5>
                                    <p>We have a good network and connectivity with top Hotels & Resorts Worldwide to cater events with all professional standards.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <div class="feature-box-1 services">
                                <div class="icon bgc-primary">
                                    <span class="text-white">3</span>
                                </div>
                                <div class="feature-content">
                                    <h5 class="feature-title mb-0">Fleets & Ground Managements</h5>
                                    <p>We have cutting-edge fleets and a good stable network for ground management to take care of everything from ground support to hotel and airport transfer, sightseeing, or local guides.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <div class="feature-box-1 services">
                                <div class="icon bgc-primary">
                                    <span class="text-white">4</span>
                                </div>
                                <div class="feature-content">
                                    <h5 class="feature-title mb-0">Artist Management</h5>
                                    <p>For Artist management for events, our goal would be to help artists achieve success by providing them with the support and resources they need to build and maintain their careers within the event industry.</p>
                                        <!-- Identifying and securing suitable performers for the conference, based on budget and audience demographics. Supervising and overseeing the production of performances, including sound and lighting setup, stage design, and special effects. -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="position-relative conf">
                        <div>
                            <img src="../../../assets/img/we-serve.jpg" class="confImg img-fluid">
                        </div>
                        <div class="feature-box-1">
                            <div (click)="openVideoDialog()">
                                <div class="position-relative cursor-pointer">
                                    <img src="../../../assets/img/meet-thumb.jpg" class="img-fluid">
                                    <div class="overlay"></div>
                                </div>
                                <div class="icon play cursor-pointer">
                                    <img src="../../../assets/img/play-button.png">
                                </div>
                                <div class="feature-content pt-1">
                                    <h5 class="feature-title text-center text-lh-1dot4 mb-0">For all your MICE and Travel Services</h5>
                                    <!-- <p class="text-color">Dec 12, 2022</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ccontainer">
                        <div class="slider">
                                    <img src="../../../assets/img/hotels.jpg" alt="image" >
                                    <img src="../../../assets/img/resorts.jpg" alt="image" >
                                    <img src="../../../assets/img/villas.jpg" alt="image" >
                                    <img src="../../../assets/img/apartments.jpg" alt="image" >
                                </div>
                            </div> -->
                </div>
            </div>
        </div>
    </section>
    <div>
        <div class="container">
            
    <!-- <div class="clients-block clients-v1 border-bottom border-color-8">
        <div class="container space-1">
            <h2 class="headingTitle font-weight-bold text-black text-center mt-3">Acceditation & Member of</h2>
            <div class="row justify-content-between pb-lg-1 members text-center text-md-left">
                <div class="col-md-12 mb-5 text-center">
                    <img class="img-fluid member-img" src="../../assets/img/members.png" alt="Image Description">
                </div>
                <div class="col-md-2 mb-5">
                    <img class="img-fluid member-img" src="../../assets/img/iata.png" alt="Image Description">
                </div>
                <div class="col-md-2 col-md mb-5">
                    <img class="img-fluid member-img" src="../../assets/img/iato.png" alt="Image Description">
                </div>
                <div class="col-md-2 col-md mb-5">
                    <img class="img-fluid member-img" src="../../assets/img/tafi.png" alt="Image Description">
                </div>
                <div class="col-md-2 col-md mb-5">
                    <img class="img-fluid member-img" src="../../assets/img/taai.png" alt="Image Description">
                </div>
                <div class="col-md-2 col-md mb-md-5">
                    <img class="img-fluid member-img" src="../../assets/img/adtoi.png" alt="Image Description">
                </div>
            </div>
        </div>
    </div>  -->

            <!-- <section class="tabs-block pt-5 pb-3">
                <div class="container">
                   <div class="flex-center-between">
                     <div class="sectionTitle pb-2">
                        <h2 class="font-weight-bold text-black mb-2 text-lh-sm headingTitle text-left">Our Case Studies</h2>
                        <p class="text-color text-left">We have the best package categories for your Holidays.</p>
                        </div>   
                        <a 
                           class="text-primary textS">View All
                        <i class="flaticon-right-arrow ml-2"></i>
                        </a>
                     </div>
                     <div class="position-relative">
                         <drag-scroll #nav1 class="d-flex flex-center-between overflowY tours-items">
                            <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                               <a target="_blank">
                                  <div class="cardItem up h-100">
                                     <div class="position-relative">
                                        <a class="d-block nobbr">
                                            <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/hotels.jpg" alt="img">
                                        </a>
                                     </div>
                                     <div class="card-body pcontent px-3 py-2">
                                        <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Hotels</a>
                                        <div>
                                            <span class="font-size-14 text-color">873,295 hotels</span>
                                        </div>
                                     </div>
                                  </div>
                               </a>
                            </div>
                            <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                <a target="_blank">
                                   <div class="cardItem up h-100">
                                      <div class="position-relative">
                                         <a class="d-block nobbr">
                                             <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/resorts.jpg" alt="img">
                                         </a>
                                      </div>
                                      <div class="card-body pcontent px-3 py-2">
                                         <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Resorts</a>
                                         <div>
                                            <span class="font-size-14 text-color">17,946 resorts</span>
                                         </div>
                                      </div>
                                   </div>
                                </a>
                             </div>
                             <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                <a target="_blank">
                                   <div class="cardItem up h-100">
                                      <div class="position-relative">
                                         <a class="d-block nobbr">
                                             <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/villas.jpg" alt="img">
                                         </a>
                                      </div>
                                      <div class="card-body pcontent px-3 py-2">
                                         <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Villas</a>
                                         <div>
                                            <span class="font-size-14 text-color">466,217 villas</span>
                                         </div>
                                      </div>
                                   </div>
                                </a>
                             </div>
                             <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                <a target="_blank">
                                   <div class="cardItem up h-100">
                                      <div class="position-relative">
                                         <a class="d-block nobbr">
                                             <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/apartments.jpg" alt="img">
                                         </a>
                                      </div>
                                      <div class="card-body pcontent px-3 py-2">
                                         <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Apartments</a>
                                         <div>
                                            <span class="font-size-14 text-color">889,155 apartments</span>
                                         </div>
                                      </div>
                                   </div>
                                </a>
                             </div>
                         </drag-scroll>
                        <button mat-raised-button (click)="moveLeft()" class="arrow prev d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_left</mat-icon></button>
                        <button mat-raised-button (click)="moveRight()" class="arrow next d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_right</mat-icon></button>
                      </div>
                </div>
             </section> -->
             <!-- <div class="row mt-4">
                <div class="col-md-12 text-center">
                   <button mat-raised-button color="accent" class="pBtn">More Packages</button>
                </div>
             </div> -->
             <!-- Product Cards -->
        <!-- <div class="product-card-block product-card-v1">
            <div class="container space-1">
                <div class="card-area pb-0">
                    <section class="tabs-block pt-5 pb-3">
                       <div class="container">
                          <div class="flex-center-between">
                            <div class="sectionTitle pb-2">
                               <h2 class="font-weight-bold text-black mb-2 text-lh-sm headingTitle text-left">Our Events</h2>
                               <p class="text-color text-left">We have the best package categories for your Holidays.</p>
                               </div>   
                               <a 
                                  class="text-primary textS">View All
                               <i class="flaticon-right-arrow ml-2"></i>
                               </a>
                            </div>
                            <div class="position-relative">
                                <drag-scroll #nav1 class="d-flex flex-center-between overflowY tours-items">
                                   <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                      <a target="_blank">
                                         <div class="cardItem up h-100">
                                            <div class="position-relative">
                                               <a class="d-block nobbr">
                                                   <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/hotels.jpg" alt="img">
                                               </a>
                                            </div>
                                            <div class="card-body pcontent px-3 py-2">
                                               <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Hotels</a>
                                               <div>
                                                   <span class="font-size-14 text-color">873,295 hotels</span>
                                               </div>
                                            </div>
                                         </div>
                                      </a>
                                   </div>
                                   <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                       <a target="_blank">
                                          <div class="cardItem up h-100">
                                             <div class="position-relative">
                                                <a class="d-block nobbr">
                                                    <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/resorts.jpg" alt="img">
                                                </a>
                                             </div>
                                             <div class="card-body pcontent px-3 py-2">
                                                <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Resorts</a>
                                                <div>
                                                   <span class="font-size-14 text-color">17,946 resorts</span>
                                                </div>
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                    <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                       <a target="_blank">
                                          <div class="cardItem up h-100">
                                             <div class="position-relative">
                                                <a class="d-block nobbr">
                                                    <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/villas.jpg" alt="img">
                                                </a>
                                             </div>
                                             <div class="card-body pcontent px-3 py-2">
                                                <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Villas</a>
                                                <div>
                                                   <span class="font-size-14 text-color">466,217 villas</span>
                                                </div>
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                    <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3">
                                       <a target="_blank">
                                          <div class="cardItem up h-100">
                                             <div class="position-relative">
                                                <a class="d-block nobbr">
                                                    <img class="card-img-top pkg-img nobbr property" src="../../../assets/img/apartments.jpg" alt="img">
                                                </a>
                                             </div>
                                             <div class="card-body pcontent px-3 py-2">
                                                <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">Apartments</a>
                                                <div>
                                                   <span class="font-size-14 text-color">889,155 apartments</span>
                                                </div>
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                </drag-scroll>
                               <button mat-raised-button (click)="moveLeft1()" class="arrow prev d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_left</mat-icon></button>
                               <button mat-raised-button (click)="moveRight1()" class="arrow next d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_right</mat-icon></button>
                             </div>
                       </div>
                    </section>
                  </div>
            </div>
        </div> -->
        <!-- End Product Cards -->
           
                <section class="py-5 mx-auto">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="position-relative">
                                <h2 class="font-weight-bold text-black headingTitle font-size-24">Frequently asked questions</h2>
                                <p class="text-color mb-3">Few common questions about our services including products and more..</p>
                            <!-- <h4 id="faq" class="font-size-22 font-weight-bold text-dark mb-4">
                                    Frequently asked questions
                                </h4> -->
                                <mat-accordion class="faq">
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false" class="mb-3 cont">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h6>What kind of events do we organize under MICE?</h6>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <p>MICE events can include a wide variety of events, such as:<br>
                                        <ul class="pl-5">
                                            <li>Corporate meetings and conferences</li>
                                            <li>Trade shows and exhibitions</li>
                                            <li>Incentive travel programs</li>
                                            <li>Product launches and brand activations</li>
                                            <li>Team building and training events.</li>
                                        </ul>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mb-2">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h6>How do we organize a successful MICE event?</h6>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <p>To organize a successful MICE event, it is important to plan and coordinate with different stakeholders, including the venue, catering, transportation, and other logistics. We also consider factors such as the theme, budget, and marketing strategy of the event, and make sure to provide a good experience for attendees. Additionally, we use the right tools and platforms like event management software to help manage the event more easily.</p>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mb-2">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h6>How do we find the right venue for the MICE event?</h6>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <p>When looking for the right venue for your MICE event, we consider factors such as the location, capacity, and amenities. It's important to consider the number of attendees and their needs when choosing the venue, such as accessibility, parking, and lodging options. We also consider the theme and style of the event, and make sure that the venue aligns with that theme.</p>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mb-2">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h6>How do we create a memorable experience for attendees at the MICE event?</h6>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <p>Creating a memorable experience for attendees at your MICE event can include a variety of strategies, such as incorporating interactive elements, providing high-quality food and beverages, and offering entertainment or activities that align with the event's theme. Additionally, providing attendees with opportunities to network and connect with other attendees can help create a memorable experience.</p>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </div>
                            <!-- <div class="ccontainer">
                                <div class="slider">
                                    <img src="../../../assets/img/hotels.jpg" alt="image" >
                                    <img src="../../../assets/img/resorts.jpg" alt="image" >
                                    <img src="../../../assets/img/villas.jpg" alt="image" >
                                    <img src="../../../assets/img/apartments.jpg" alt="image" >
                                </div>
                            </div> -->
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <!-- Icon Block v1 -->
        <div class="icon-block-center icon-center-v1 wcu-bg">
            <div class=" wcu-gradient-overlay text-center space-1">
            <div class="container text-center space-1">
                <div class="w-md-80 w-lg-50 text-center position-relative mx-md-auto pb-3 mt-3">
                    <h2 class="section-title text-white font-size-24 font-weight-bold">Why Choose Us</h2>
                </div>
                <div class="mb-2">
                    <div class="row wcu">
                        <div class="col-md-4 item">
                            <i class="flaticon-price text-white font-size-70 "></i>
                            <div>
                            <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Competitive Pricing</a></h5>
                            <p class="text-white px-xl-2 px-uw-7">We work with a wide range of suppliers to ensure that our clients get the best deals on flights, hotels, and activities.</p>
                        </div>
                        </div>
                        <div class="col-md-4 item">
                            <i class="flaticon-medal text-white font-size-70 "></i>
                            <div>
                            <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Strong Reputation</a></h5>
                            <p class="text-white px-xl-2 px-uw-7">We have built a strong reputation for providing high-quality service and creating memorable vacations for our clients.</p>
                        </div>
                        </div>
                        <div class="col-md-4 item">
                            <i class="flaticon-global-1 text-white font-size-70 "></i>
                            <div>
                                <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Wide range of Destinations</a></h5>
                                <p class="text-white px-xl-2 px-uw-7">We offer popular tourist destinations, as well as off-the-beaten-path locations for those looking for a more unique experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Icon Block v1 -->
    <!-- Clients v1 -->
    <!-- End Clients v1 -->
</main>
