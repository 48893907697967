import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviourService {
  private login$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor() { }

  setLogin(user) {
    this.login$.next(user);
  }

  getLogin(): Observable<any> {
    return this.login$.asObservable();
  }
}
