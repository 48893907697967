<footer class="footer ">
    <div class="space-top-p2 pb-1">
        <div class="container">
            <div class="row justify-content-xl-between">
                <div class="col-lg-3 col-md-3 mb-6 mb-md-10 mb-xl-0">
                    <!-- Contacts -->
                    <div class ="d-md-flex d-lg-block">
                        <div class="mb-3 mr-md-7 mr-lg-0">
                            <h4 class="h6 mb-2 font-weight-bold textM text-black">Need {{contact_details.CONTACT_APP_NAME}} Help?</h4>
                            <a>
                                <div class="d-flex">
                                    <div>
                                        <a href="tel:{{contact_details.CONTACT_NUMBER}}" mat-button class="text-black p-0 mr-2"><mat-icon>call</mat-icon> {{contact_details.CONTACT_NUMBER}}</a>
                                        <a mat-button class="text-black p-0"><mat-icon>mail</mat-icon> {{contact_details.CONTACT_EMAIL}}</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="ml-md-6 ml-lg-0">
                            <div class="mb-0">
                                <h4 class="h6 font-weight-bold mb-2 textM text-black">Contact Info</h4>
                                <address class="pr-4 mb-0">
                                    <span class="mb-2 font-size-15 font-weight-normal text-gray-1">
                                        {{contact_details.CONTACT_ADDRESS}}
                                    </span>
                                </address>
                            </div>
                        </div>
                    </div>
                    <!-- End Contacts -->
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="row">
                <div class="col-6 col-md-4 col-lg-4 col-sm-6 col-6 mb-6 mb-md-10 mb-xl-0">
                    <h4 class="textM font-weight-bold mb-2  text-black">Quick Links</h4>
                    <!-- List Group -->
                    <ul class="list-group list-group-flush list-group-borderless mb-0">
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/about.html">Holidays</a></li>
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action"  [routerLink]="['/hotel']">Hotels</a></li>
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/contact.html">Flights</a></li>
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/contact.html">Activities</a></li>
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/contact.html">Transfer</a></li>
                        </ul>
                    <!-- End List Group -->
                </div>

                <div class="col-6 col-md-4 col-lg-4  mb-6 mb-md-0">
                    <h4 class="textM font-weight-bold mb-2 text-black">Company</h4>
                    <!-- List Group -->
                    <ul class="list-group list-group-flush list-group-borderless mb-0">
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/about.html">About us</a></li>
                        <li><a class="text-decoration-on-hover list-group-item list-group-item-action" href="../others/contact.html">Contact Us</a></li>
                        <li>
                            <a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Terms & Conditions</a>
                        </li>
                        <li>
                            <a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Privacy Policy</a>
                        </li>
                        <li>
                            <a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Affiliate Program</a>
                        </li>
                        <!-- <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Give Us Feedbacks</a></li> -->
                    </ul>
                    <!-- End List Group -->
                </div>
                <div class="col-12 col-md-4 col-lg-4  mb-6 mb-md-10 mb-xl-0">
                    <h4 class="textM font-weight-bold mb-2 text-black">Other Services</h4>
                    <!-- List Group -->
                    <ul class="list-group list-group-flush list-group-borderless mb-0">
                        <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/about.html">Investor Relations</a></li>
                        <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/about.html">Rewards Program</a></li>
                        <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/about.html">PointsPLUS</a></li>
                        <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/about.html">Partners</a></li>
                        <li><a class="list-group-item list-group-item-action text-decoration-on-hover" href="../others/about.html">List My Hotel</a></li>
                    </ul>
                    <!-- End List Group -->
                </div>
                    </div>

                </div>
                <div class="col-md-3 col-lg-3">
                    <div class="row align-items-md-center dHide">
                        <div class="col-md-12 align-items-center mb-3 mb-xl-0">
                            <!-- Logo -->
                            <div class="social-media d-flex flex-center-between">
                                <a><span class="social facebook"></span></a>
                                <a><span class="social instagram"></span></a>
                                <a><span class="social twitter"></span></a>
                                <a><span class="social linkedin"></span></a>
                                <a><span class="social youtube"></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 mb-xl-2">
                        <h4 class="h6 font-weight-bold mb-2 mb-xl-4 text-black">Subscribe for Trip Updates</h4>
                        <p class="m-0  font-size-15 text-gray-1">Subscribe for our mailing list to get latest updates and offers.</p>
                    </div>
                    <div class="d-flex flex-center-between">
                        <label class="sr-only text-gray-1" for="subscribeSrEmailExample1">Your Email</label>
                        <div class="input-group d-flex flex-center-between">
                            <mat-form-field appearance="" class="w-100">
                                <mat-label>Your Email Id</mat-label>
                                <input matInput placeholder="Placeholder">
                                <mat-icon matSuffix>mail</mat-icon>
                              </mat-form-field>
                               <!-- <input type="email" class="form-control height-45 font-size-14 border-radius-3 border-width-2 border-color-8" name="email" id="subscribeSrEmailExample1" placeholder="Your Email" aria-label="Your email address" aria-describedby="subscribeButtonExample3" required="" data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success"> -->
                            <div class="d-block align-center">
                                <button mat-raised-button color="primary">Subscribe</button>
                            </div>
                        </div>
                        </div>

                </div>
            </div>
        </div>
    </div>
    <div class=" space-1">
        <div class="container">
            <div class="row flex-center-between align-items-md-center">
                <div class="col-md-3 align-items-center mb-3 mb-xl-0 mHide">
                    <!-- Logo -->
                    <div class="social-media flex-center-between">
                        <a><span class="social facebook"></span></a>
                        <a><span class="social instagram"></span></a>
                        <a><span class="social twitter"></span></a>
                        <a><span class="social linkedin"></span></a>
                        <a><span class="social youtube"></span></a>
                        <!-- <a class=""><i class="fab fa-facebook-f"></i></a>
                        <a class=""><i class="fab fa-instagram"></i></a>
                        <a class=""><i class="fab fa-twitter"></i></a>
                        <a class=""><i class="fab fa-whatsapp"></i></a>
                        <a class=""><i class="fab fa-linkedin-in"></i></a> -->

                    </div>
                    <!-- End Logo -->
                </div>
                <div class="col-md-5 mb-3 align-items-center">
                    <img class="member-img full-width" src="../../assets/img/members.png">
                </div>
                <div class="col-md-3 mb-3 align-items-center">
                        <img class="payment-img full-width" src="../../assets/img/payment.png">
                        <!-- <ul class="d-flex list-unstyled mb-0">
                            <li class="mr-2 ml-0"><img class="max-width-40" src="../../assets/img/icons/img3.png" alt="Payment Icons"></li>
                            <li class="mx-2"><img class="max-width-40" src="../../assets/img/icons/img4.png" alt="Payment Icons"></li>
                            <li class="mx-2"><img class="max-width-40" src="../../assets/img/icons/img5.png" alt="Payment Icons"></li>
                            <li class="mx-2"><img class="max-width-40" src="../../assets/img/icons/img6.png" alt="Payment Icons"></li>
                            <li class="ml-2 mr--0"><img class="max-width-40" src="../../assets/img/icons/img7.png" alt="Payment Icons"></li>
                        </ul> -->
                    </div>
            </div>
                    <!-- <select class="js-select selectpicker dropdown-select min-width-256 w-100 w-md-auto border-radius-3 mb-3 mb-md-0 mr-md-5"
                        data-style="border border-width-2 border-color-8">
                        <option class="border-bottom py-2 font-size-16" value="one" selected>English(United States)</option>
                        <option class="border-bottom py-2 font-size-16" value="one">English(United Kingdom)</option>
                        <option class="border-bottom py-2 font-size-16" value="one">English</option>
                        <option class="border-bottom py-2 font-size-16" value="two">Deutsch</option>
                        <option class="border-bottom py-2 font-size-16" value="two">Francais</option>
                        <option class="py-2 font-size-16" value="four">Espanol</option>
                    </select>
                    <select class="js-select selectpicker dropdown-select pr-0 max-width-155 w-100 border-radius-3"
                        data-style="border border-width-2 border-color-8">
                        <option class="border-bottom py-2 font-size-16" value="one" selected>$ USD</option>
                        <option class="border-bottom py-2 font-size-16" value="two">€ EUR</option>
                        <option class="border-bottom py-2 font-size-16" value="three">₺ TL</option>
                        <option class="py-2 font-size-16" value="four">₽ RUB</option>
                    </select> -->
                </div>
            </div>

    <div class="space-p2 fContent">
        <div class="container">
            <div class="row justify-content-xl-between">
                <div class="col-12 col-lg-12  mb-6 mb-md-10 mb-xl-0">
                    <!-- Contacts -->
                    <div class ="d-md-flex d-lg-block">
                        <div class="ml-md-12 ml-lg-0">
                            <div class="mb-4">
                                <h4 class="h6 font-weight-bold mb-2 font-size-17 footer-text-grey">Tripzolo India Private Limited</h4>
                                <address class="pr-4">
                                    <span class="mb-2 h6 font-weight-normal footer-text-grey">
                                        Tripzolo India is a reputed and full-service travel management company specializing in leisure and corporate travel with a personal touch with an emphasis on customized and tailor-made travel products, and experiences based on clients' expectations and comfort.
                                    </span>
                                </address>
                            </div>
                        </div>
                    </div>
                    <!-- End Contacts -->
                </div>
                <!-- <div class="col-12 col-lg-6  mb-6 mb-md-10 mb-xl-0">
                    <div class ="d-md-flex d-lg-block">
                        <div class="ml-md-12 ml-lg-0">
                            <div class="mb-4">
                                <h4 class="h6 font-weight-bold mb-2 font-size-17 footer-text-grey">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
                                <address class="pr-4">
                                    <span class="mb-2 h6 font-weight-normal footer-text-grey">
                                       Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quaerat asperiores nemo vitae! Aspernatur quibusdam sunt a consectetur beatae exercitationem fugiat quisquam odit nisi alias? Quos quidem dolor in dicta.
                                    </span>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6  mb-6 mb-md-10 mb-xl-0">
                    <div class ="d-md-flex d-lg-block">
                        <div class="ml-md-12 ml-lg-0">
                            <div class="mb-4">
                                <h4 class="h6 font-weight-bold mb-2 font-size-17 footer-text-grey">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
                                <address class="pr-4">
                                    <span class="mb-2 h6 font-weight-normal footer-text-grey">
                                       Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quaerat asperiores nemo vitae! Aspernatur quibusdam sunt a consectetur beatae exercitationem fugiat quisquam odit nisi alias? Quos quidem dolor in dicta.
                                    </span>
                                </address>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>

    <div class="space-1">
        <div class="container">
            <div align="center" class="d-lg-flex d-md-block justify-content-between align-items-center">
                <!-- Copyright -->
                <p  class="text-black bfooter mb-3 mb-lg-0 align-items-center">© 2021 {{contact_details.CONTACT_APP_NAME}}. All rights reserved</p>
                <!-- End Copyright -->

            </div>
        </div>
    </div>
</footer>
