import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviourService } from '../services/behaviour.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  logged_user: boolean = false;
  constructor(private router: Router, private behaviour: BehaviourService, private location: Location,
  private route:ActivatedRoute, private _injector: Injector) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      return this.isValidUser(route);
  }

  isValidUser(route): any {
    this.behaviour.getLogin().subscribe(logged_user => {
      if(logged_user === false){
        if(this.route.snapshot['_routerState']._root.children[0].value.routeConfig.path.indexOf("my-account") !== -1){
          this.router.navigate(['/']);
          this.logged_user = false;
        }
      }
      else{
        this.logged_user = true;
      }
    });
    // return this.logged_user;
    if(this.logged_user){
      return true;
    }else{
      return false;
    }

  }
}
