<main id="content">
    <!-- ========== Top Screen ========== -->
    <div class="hero-block homeBanner ">
      <div class="csslider infinity ban-slider" id="slider1">
            <input type="radio" name="slides" checked="checked" id="slides_1"/>
            <input type="radio" name="slides" id="slides_2"/>
            <input type="radio" name="slides" id="slides_3"/>
            <input type="radio" name="slides" id="slides_4"/>
         <ul>
            <li><img src="../../../assets/img/banner/ladakh.jpg"/></li>
            <li><img src="../../../assets/img/banner/rajasthan.jpg"/></li>
            <li><img src="../../../assets/img/banner/andaman.jpg"/></li>
            <li><img src="../../../assets/img/banner/wildlife1.jpg"/></li>
        </ul>
        <div class="banner-overlay"></div>
        <div class="arrows">
            <label for="slides_1"></label>
            <label for="slides_2"></label>
            <label for="slides_3"></label>
            <label for="slides_4"></label>
            <label class="goto-first" for="slides_1"></label>
            <label class="goto-last" for="slides_4"></label>
         </div>
         <div class="banner-content">
         <div class="container space-2 space-top-xl-9 space-top-pd">
            <div class="navigation"> 
              <div>
                <label for="slides_1"><img class="thumb" src="../../../assets/img/banner/ladakh.jpg"/></label>
                <label for="slides_2"><img class="thumb" src="../../../assets/img/banner/rajasthan.jpg"/></label>
                <label for="slides_3"><img class="thumb" src="../../../assets/img/banner/andaman.jpg"/></label>
                <label for="slides_4"><img class="thumb" src="../../../assets/img/banner/wildlife1.jpg"/></label>
              </div>
          </div>
            <div class="row pb-xl-2__5">
               <!-- Banner Content -->
               <div class="pt-2 pb-2">
                  <!-- <h1 class="font-size-60 font-size-xs-30 text-white font-weight-bold m-0 mHide">Welcome To trip<span class="text-pcolor">zolo</span> !  <small class="tagline sHide">Escape To Explore</small></h1> -->
                  <div class="main-banner-title text-white font-weight-bold m-0 mHide">
                     Escape to <span class="highlight-text">Wonders</span> <br>
                     Let's Explore the World !
                     <!-- <span class="font-size-40 font-weight-bold text-uppercase">simple</span> and
                     <span class="font-size-40 font-weight-bold text-uppercase">funfilled</span> as possible 
                     <mat-icon>format_quote</mat-icon> -->
                     </div>
                  <p class="text-white">We’re truely dedicated to make your travel experience<br>
                     as much SIMPLE and FUNFILLED as possible</p>
                  <!-- <p class="headingTitle font-weight-normal text-white mHide">
                     We’re truely dedicated to make your travel experience as much simple and fun as possible!
                     </p> -->
                  <!-- <p class="font-weight-normal light-blue pt-4 m-0 p-2">Find the best Holiday for you across India !
                  </p> -->
               </div>
               <!-- <div class="tp-parallax-wrap sHide"><img src="./../../../assets/img/plane.png" alt="Trip" class="planeImg">
               </div> -->
               <!-- End Banner Content -->
            </div>
            <div class="absCard pt-5" id="search">
               <!-- Nav with Icons -->
               <ul class="nav tab-nav-rounded flex-nowrap tab-nav py-4 pt-2 search-tabs" role="tablist">
                  <li class="nav-item ">
                      <a class="nav-link active" id="pills-two-example2-tab" data-toggle="pill" role="tab" aria-controls="pills-two-example2" aria-selected="true">
                          <div class="d-flex flex-column flex-md-column  position-relative text-color align-items-center">
                              <figure class=" menu-icons ie-height-40 d-md-block ">
                                  <span class="icon holidays font-size-3"></span>
                              </figure>
                              <span class="tabtext mt-2">Holidays</span>
                          </div>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-one-example2-tab" data-toggle="pill" [routerLink]="['/hotel']" role="tab" aria-controls="pills-one-example2" aria-selected="true">
                          <div class="d-flex flex-column flex-md-column position-relative text-white align-items-center">
                              <figure class=" menu-icons ie-height-40 d-md-block ">
                                  <span class="icon hotels font-size-3"></span>
                              </figure>
                              <span class="tabtext mt-2">Hotels</span>
                          </div>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-seven-example2-tab" data-toggle="pill" [routerLink]="['/flight']" role="tab" aria-controls="pills-seven-example2" aria-selected="true">
                          <div class="d-flex flex-column flex-md-column position-relative  text-white align-items-center">
                              <figure class=" menu-icons ie-height-40 d-md-block ">
                                  <span class="icon flights font-size-3"></span>
                              </figure>
                              <span class="tabtext mt-2">Flights</span>
                          </div>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-three-example2-tab" data-toggle="pill" [routerLink]="['/activity']" role="tab" aria-controls="pills-three-example2" aria-selected="true">
                          <div class="d-flex flex-column flex-md-column position-relative text-white align-items-center">
                              <figure class=" menu-icons  ie-height-40  d-md-block ">
                                  <span class="icon activities  font-size-3"></span>
                              </figure>
                              <span class="tabtext mt-2">Activity</span>
                          </div>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="pills-seven-example2-tab" data-toggle="pill" [routerLink]="['/flight']" role="tab" aria-controls="pills-seven-example2" aria-selected="true">
                          <div class="d-flex flex-column flex-md-column position-relative  text-white align-items-center">
                              <figure class=" menu-icons ie-height-40 d-md-block ">
                                  <span class="icon transfer font-size-3"></span>
                              </figure>
                              <span class="tabtext mt-2">Transfer</span>
                          </div>
                      </a>
                  </li>
                  </ul>
               <!-- End Nav with Icons -->
               <div class="tab-content hero-tab-pane">
                  <div class="tab-pane fade active show" id="pills-one-example2" role="tabpanel"
                     aria-labelledby="pills-one-example2-tab">
                     <!-- Search Section -->
                     <div class="row">
                        <div class="col-md-7 searchBox">
                           <div class="card border-0 ">
                              <div class="card-body px-2">
                                 <!-- <div class="row"><div class="col-md-12">
                                    <h4 class="srchHeading">Find the best Holiday for you across India !</h4>
                                    </div></div> -->
                                 <app-tour-modify-search></app-tour-modify-search>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-2 planTrips mHide">
                           <!-- <div class="pmt">
                              <span class="textXS">Plan a own customized tailor-made trip, </span>
                              <span class="textS d-block font-weight-bold mb-2">Speak to our Destination Expert </span>
                              <a  [routerLink]="['/tour/plan-my-trip']" mat-raised-button color="accent" class="mat-focus-indicator planBtn pBtn height-46 mat-raised-button mat-button-base mat-accent" style="
                                 height: 34px;
                                 ">Plan My Trip</a>
                           </div> -->
                        </div>
                     </div>
                     <!-- End Search Section -->
                  </div>
               </div>
            </div>
         </div>
         <div class="icon-block-center icon-center-v1 features mHide">
            <div class="space-1">
               <div class="container">
                  <div class="row wcu1">
                     <div class="col-md-4 item p-2">
                        <i class="flaticon-price text-white font-size-40 p-2"></i>
                        <div class="text-left">
                           <h5 class="font-size-16 text-white font-weight-bold mb-2 px-xl-2 px-uw-7">
                              <a>Competitive Pricing</a>
                           </h5>
                           <p class="text-white font-size-14 px-xl-2 px-uw-7 mb-0">With 500+ suppliers and the
                              purchasing power of 300 million members, mytravel.com can save you more!
                           </p>
                        </div>
                     </div>
                     <div class="col-md-4 item p-2">
                        <i class="flaticon-medal text-white font-size-40 p-2"></i>
                        <div class="text-left">
                           <h5 class="font-size-16 text-white font-weight-bold mb-2 px-xl-2 px-uw-7">
                              <a>Award-Winning Service</a>
                           </h5>
                           <p class="text-white font-size-14 px-xl-2 px-uw-7 mb-0">Travel worry-free knowing that
                              we're here if you needus, 24 hours a day
                           </p>
                        </div>
                     </div>
                     <div class="col-md-4 item p-2">
                        <i class="flaticon-global-1 text-white font-size-40 p-2"></i>
                        <div class="text-left">
                           <h5 class="font-size-16 text-white font-weight-bold mb-2 px-xl-2 px-uw-7"><a>Worldwide Coverage</a></h5>
                           <p class="text-white font-size-14 px-xl-2 px-uw-7 mb-0">Over 1,200,000 hotels in more
                              than 200 countries and regions & flights to over 5,000 cities
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
      </div>
       
       
       <!-- <mat-carousel
            timings="250ms ease-in"
            [autoplay]="true"
            interval="5000"
            color="accent"
            maxWidth="auto"
            proportion="25"
            slides="3"
            [loop]="true"
            [hideArrows]="false"
            [hideIndicators]="false"
            [useKeyboard]="true"
            [useMouseWheel]="false"
            orientation="ltr"
            >
            <mat-carousel-slide
               #matCarouselSlide
               *ngFor="let slide of banner; let i = index"
               [image]="slide.image"
               overlayColor="#00000040"
               [hideOverlay]="false"
            ></mat-carousel-slide>
            </mat-carousel> -->
       
       
    </div>
    <!-- ========== End Top Screen ========== -->
    <div class="icon-block-center icon-center-v1 dHide">
       <div class="space-1">
          <div class="container">
             <div class="row wcu1">
                <div class="col-md-4 item p-2">
                   <i class="flaticon-price text-black font-size-40 p-2"></i>
                   <div>
                      <h5 class="font-size-16 text-black font-weight-bold mb-2 px-xl-2 px-uw-7">
                         <a>Competitive Pricing</a>
                      </h5>
                      <p class="text-black font-size-14 px-xl-2 px-uw-7 mb-0">With 500+ suppliers and the
                         purchasing power of 300 million members, mytravel.com can save you more!
                      </p>
                   </div>
                </div>
                <div class="col-md-4 item p-2">
                   <i class="flaticon-medal text-black font-size-40 p-2"></i>
                   <div>
                      <h5 class="font-size-16 text-black font-weight-bold mb-2 px-xl-2 px-uw-7">
                         <a>Award-Winning Service</a>
                      </h5>
                      <p class="text-black font-size-14 px-xl-2 px-uw-7 mb-0">Travel worry-free knowing that
                         we're here if you needus, 24 hours a day
                      </p>
                   </div>
                </div>
                <div class="col-md-4 item p-2">
                   <i class="flaticon-global-1 text-black font-size-40 p-2"></i>
                   <div>
                      <h5 class="font-size-16 text-black font-weight-bold mb-2 px-xl-2 px-uw-7"><a>Worldwide Coverage</a></h5>
                      <p class="text-black font-size-14 px-xl-2 px-uw-7 mb-0">Over 1,200,000 hotels in more
                         than 200 countries and regions & flights to over 5,000 cities
                      </p>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <section class="pt-7 pb-5 startsec">
       <div class="container">
          <div class="row flex-center-between">
             <div class="col-md-4 sectionTitle">
                <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">
                   Tripzolo <span class="d-block">- Escape to Explore</span>
                </h2>
                <p class="text-color">Tripzolo India is a reputed and full-service travel management company specializing in leisure and corporate travel with a personal touch with an emphasis on customized and tailor-made travel products, and experiences based on clients' expectations and comfort.</p>
                <a href="#" mat-raised-button color="primary" class="textS">Explore Packages
                <i class="flaticon-right-arrow ml-2"></i>
                </a>
             </div>
             <div class="col-md-8 tz-section">
                <div class="row items">
                    <div class=" col-md-4 item deal1">
                        <figure class="position-relative">
                            <img src="./../../../assets/img/tz-holidays.jpg" class="dealImg tl-rad">
                            <div class="backdrop"></div>
                        </figure>
                        <div class="dealContent">
                            <h6 class="card-subtitle text-block">Exclusive Holiday Package</h6>
                        </div>
                    </div>
                    <div class=" col-md-4 item deal2">
                        <figure class="position-relative">
                            <img src="./../../../assets/img/tz-hotels.jpg" class="dealImg">
                            <div class="backdrop"></div>
                        </figure>
                        <div class="dealContent">
                            <h6 class="card-subtitle text-block">Top rated Hotels & Resorts</h6>
                        </div>
                    </div>
                    <div class=" col-md-4 item deal3">
                        <figure class="position-relative">
                            <img src="./../../../assets/img/tz-wildlife.jpg" class="dealImg">
                            <div class="backdrop"></div>
                        </figure>
                        <div class="dealContent">
                            <h6 class="card-subtitle text-block">Feel the Nature</h6>
                        </div>
                    </div>
                </div>
             </div>
          </div>
          <section *ngIf="processSection" id="scrl"
             class="mx-auto p-3 mt-4 process animate__animated animate__backInRight">
             <div class="row">
                <div class="col-md-12">
                   <h2
                      class="section-title text-white font-size-30 font-weight-bold mb-0 d-flex flex-center-between">
                      <span>Our Work Process</span>
                      <mat-icon (click)=closeProcess($event)>close</mat-icon>
                   </h2>
                   <p class="text-white">Follow these steps and get your perfect booking. </p>
                </div>
             </div>
             <div class="row pb-3">
                <div class="col-md-12">
                   <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                      <div class="work-process-icon-wrap pBtn rounded">
                         <mat-icon class="icon">search</mat-icon>
                         <span class="process-step bg-pcolor text-pcolor shadow-sm">1</span>
                      </div>
                      <span class="work-process-divider">
                         <mat-icon class="arrow ">send</mat-icon>
                      </span>
                      <div class="textS mt-4">
                         <h5 class="text-white">Search Destination / City</h5>
                         <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                            services. 
                         </p>
                      </div>
                   </div>
                   <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                      <div class="work-process-icon-wrap pBtn rounded">
                         <mat-icon class="icon">date_range</mat-icon>
                         <span class="process-step bg-pcolor text-pcolor shadow-sm">2</span>
                      </div>
                      <span class="work-process-divider">
                         <mat-icon class="arrow ">send</mat-icon>
                      </span>
                      <div class="textS mt-4">
                         <h5 class="text-white">Select Travel Date</h5>
                         <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                            services. 
                         </p>
                      </div>
                   </div>
                   <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                      <div class="work-process-icon-wrap pBtn rounded">
                         <mat-icon class="icon">view_list</mat-icon>
                         <span class="process-step bg-pcolor text-pcolor shadow-sm">3</span>
                      </div>
                      <span class="work-process-divider">
                         <mat-icon class="arrow ">send</mat-icon>
                      </span>
                      <div class="textS mt-4">
                         <h5 class="text-white">Choose Pakage, Hotel, etc</h5>
                         <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                            services. 
                         </p>
                      </div>
                   </div>
                   <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                      <div class="work-process-icon-wrap color-4-bg rounded">
                         <mat-icon class="icon">done</mat-icon>
                         <span class="process-step complete text-pcolor shadow-sm">4</span>
                      </div>
                      <span class="work-process-divider">
                         <mat-icon class="arrow ">send</mat-icon>
                      </span>
                      <div class="textS mt-4">
                         <h5 class="text-white">Book Now !</h5>
                         <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                            services. 
                         </p>
                      </div>
                   </div>
                </div>
             </div>
          </section>
          <!--   <section class="mx-auto pt-5 mb-4">
             <div class="row">
                 <div align="center" class="col-md-6 order2">
                   <img class="serv mHide" src="./../../../assets/img/service.png">
                 </div>
             
                 <div class="col-md-6 order1">
                   <h3 class="font-weight-bold font-size-28"><span class="bigLetter">A</span>bout TRIP<span class="dark-pink">ZOLO</span></h3>
                   <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches and the area’s tropical spice plantations.</p>
                   <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches.</p>
                   <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches.</p>
                   <div class="d-flex cmyk">
                       <button mat-raised-button color="accent" class="pBtn">Read More</button>
                       <span class="float-right pushRight">
                   <span class="g"></span>
                   <span class="b"></span>
                   <span class="p"></span>
                   </span>
                   </div>
                 </div>
             </div>
             </section> -->
       </div>
    </section>
    <section class="tabs-block pt-5 pb-3">
       <div class="container">
          <!-- Slider Start -->
          <!-- <div id="slider" class="mb-5">
             <input type="radio" name="slider" id="slide1" checked>
             <input type="radio" name="slider" id="slide2">
             <input type="radio" name="slider" id="slide3">
             <input type="radio" name="slider" id="slide4">
             <div id="slides">
                <div id="overflow">
                   <div class="inner">
                      <div class="slide slide_1">
                         <div class="slide-content">
                            <h2>Slide 1</h2>
                            <p>Content for Slide 1</p>
                         </div>
                      </div>
                      <div class="slide slide_2">
                         <div class="slide-content">
                            <h2>Slide 2</h2>
                            <p>Content for Slide 2</p>
                         </div>
                      </div>
                      <div class="slide slide_3">
                         <div class="slide-content">
                            <h2>Slide 3</h2>
                            <p>Content for Slide 3</p>
                         </div>
                      </div>
                      <div class="slide slide_4">
                         <div class="slide-content">
                            <h2>Slide 4</h2>
                            <p>Content for Slide 4</p>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div id="controls">
                <label for="slide1"></label>
                <label for="slide2"></label>
                <label for="slide3"></label>
                <label for="slide4"></label>
             </div>
             <div id="bullets">
                <label for="slide1"></label>
                <label for="slide2"></label>
                <label for="slide3"></label>
                <label for="slide4"></label>
             </div>
             </div>  -->
          <!-- End Slider -->
          <div class="flex-center-between">
            <div class="sectionTitle pb-2">
               <h2 class="font-weight-bold text-black mb-0 text-lh-sm headingTitle text-left">Holiday Types</h2>
               <!-- <p class="text-color text-left">We have the best package categories for your Holidays.</p> -->

               <p class="text-color text-left">We have the best package for your Holidays.</p>
               
               </div>   
            </div>
          <mat-tab-group mat-align-tabs="left" class="holidayTypes" (selectedTabChange)="getPackages($event, 'recommended')">
             <mat-tab *ngFor="let package_mode of package_modes">
                <ng-template mat-tab-label>
                   <mat-icon class="example-tab-icon mHide">{{package_mode.icon}}</mat-icon>
                   {{package_mode.title}}
                </ng-template>
                <div *ngIf="packages.length == 0">{{package_mode.title}} not available.</div>
                  <a *ngIf="packages.length > 0" [routerLink]="['/tour/tour-list',search_date,'All',{tourtype: package_mode.name}]"
                     class="text-primary textS currenltyUn viewAll mb-2 pt-0">View All
                     <i class="flaticon-right-arrow ml-2"></i>
                  </a>
                <drag-scroll #nav class="d-flex flex-center-between overflowY tours-items" *ngIf="packages.length > 0">
                   <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-6 col-7 mb-2" *ngFor="let package of packages">
                     <a [routerLink]="['/tour/tour-detail',package.id,search_date,'All']"> 
                     <div class="cardItem h-100">
                         <div class="position-relative">
                            <a class="d-block gradient-overlay-half-bg-gradient-v5">
                              <img class="card-img-top pkg-img" src="{{package.img_url}}{{package.sliders[0].path}}" alt="img">
                              <div class="flex-center-between fbottom">
                                 <span class="textS font-weight-medium mb-0 text-white"><mat-icon class="icon small">place</mat-icon> {{package.states.join(', ')}}</span>
                                 <!-- <span class="textS font-weight-medium mb-0 text-white"><mat-icon class="icon textS small orange-text">star</mat-icon> 4.5 / 5</span> -->
                              </div>
                            </a>
                            <div class="position-absolute top-0 right-0 pt-3 pr-3">
                              <span class="badge badge-pill bg-blue-dark-1 text-white px-2 ml-3 py-2 textS font-weight-medium">{{package.package_nights}}N / {{(package.package_nights+1)}}D</span> 
                              <!-- <span class="badge badge-pill text-white bg-pcolor px-3 ml-3 py-2 textS font-weight-normal">₹ {{package.discount}} Off</span> -->
                            </div>
                         </div>
                         <div class="packageData">
                            <div class="flex-center-between">
                              <span class="textB pkgName font-weight-bold mb-0 text-dark">{{package.package_name}}</span>
                            </div>
                            <!-- <span class="d-block">
                               <div class="mb-1  align-items-center textS1 text-color  text-center">
                                  <i class="icon flaticon-clock-circular-outline mr-2 textS1"></i> {{package.package_nights}}
                                  Nights / {{(package.package_nights+1)}} Days
                               </div>
                            </span> -->
                            <!-- <span class="d-block">
                               <div class="mb-1 align-items-center textS1 text-color text-center">
                                   <i class="icon flaticon-pin-1 mr-2 textS1"></i> Mumbai, India
                               </div>
                               </span> -->
                            <div class="mt-2">
                               <span class="d-flex flexDirectionRC text-black mb-0 font-weight-bold price">
                                  <div>
                                     <small class="d-block mr-2 textS position-relative rack">₹ {{package.rack_rate}} </small>
                                  </div>
                                  <div>
                                     <span>₹ {{package.display_rate}} </span>
                                     <small class="font-size-12 pn">Per Person</small>
                                  </div>
                               </span>
                            </div>
                         </div>
                      </div>
                      </a>
                   </div>
                </drag-scroll>
               <button mat-raised-button (click)="moveLeft()" *ngIf="packages.length > 0" class="arrow prev d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_left</mat-icon></button>
               <button mat-raised-button (click)="moveRight()" *ngIf="packages.length > 0" class="arrow next d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_right</mat-icon></button>
             
                <!-- <div class="row mt-4">
                   <div class="col-md-12 text-center">
                      <button mat-raised-button color="accent" class="pBtn">More Packages</button>
                   </div>
                </div> -->
             </mat-tab>
          </mat-tab-group>
       </div>
    </section>
    <section class="pt-3 pb-3">
      <div class="container">
         <div class="ads-slides">
            <mat-carousel
            timings="250ms ease-in"
            [autoplay]="true"
            interval="5000"
            color="accent"
            maxWidth="auto"
            proportion="25"
            slides="4"
            [loop]="true"
            [hideArrows]="false"
            [hideIndicators]="false"
            [useKeyboard]="true"
            [useMouseWheel]="false"
            orientation="ltr"
            >
            <mat-carousel-slide
               #matCarouselSlide
               *ngFor="let slide of slides; let i = index"
               [image]="slide.image"
               overlayColor="#00000040"
               [hideOverlay]="false"
            ></mat-carousel-slide>
            </mat-carousel>
         </div>
      </div>
    </section>
    <section class="tabs-block pt-5 pb-3">
      <div class="container">
         <div class="flex-center-between">
           <div class="sectionTitle pb-2">
              <h2 class="font-weight-bold text-black mb-0 text-lh-sm headingTitle text-left">Top Selling Packages</h2>
              <p class="text-color text-left">We have the best package categories for your Holidays.</p>
              </div>   
              <a [routerLink]="['/tour/tour-list',search_date,'All',{search: 'top-selling'}]"
                 class="text-primary textS">View All
              <i class="flaticon-right-arrow ml-2"></i>
              </a>
           </div>
           <div class="position-relative">
               <drag-scroll #nav1 class="d-flex flex-center-between overflowY tours-items">
                  <div drag-scroll-item class="col-md-3 col-xl-3 col-sm-5 col-7 mb-3" *ngFor="let package of top_packages">
                     <a [routerLink]="['/tour/tour-detail',package.id,search_date,'All']" target="_blank">
                        <div class="cardItem up h-100">
                           <div class="position-relative">
                              <a class="d-block gradient-overlay-half-bg-gradient-v5 nobbr">
                              <img class="card-img-top pkg-img nobbr" src="{{package.img_url}}{{package.sliders[0].path}}" alt="img">
                              <div class="flex-center-between fbottom">
                                 <span class="textS font-weight-medium mb-0 text-white"><mat-icon class="icon small">place</mat-icon> {{package.states.join(', ')}}</span>
                                 <!-- <span class="textS font-weight-medium mb-0 text-white"><mat-icon class="icon textS small orange-text">star</mat-icon> 4.5 / 5</span> -->
                              </div>
                              <div class="flex-center-between ftop">
                                 <span class="badge badge-pill text-white bg-pcolor px-4 py-2 font-size-14 font-weight-normal">Popular</span>
                                 <span class="badge badge-pill bg-blue-dark-1 text-white px-2 ml-3 py-2 textS font-weight-medium">{{package.package_nights}}N / {{(package.package_nights+1)}}D</span> 
                              </div>
                           </a>
                              <div class="position-absolute bottom__30 left-0 right-0">
                                 <div class="pb-2">
                                    <!-- <span
                                       class="badge badge-pill bg-white text-primary px-4 py-2 font-size-14 font-weight-normal">
                                       <div class="tag">
                                          <mat-icon>family_restroom</mat-icon> <span>Family</span>
                                       </div><span class="text-black p-1 views"><i class="fa fa-eye"></i> 1.2k</span>
                                       </span> -->
                                 </div>
                              </div>
                           </div>
                           <div class="card-body pcontent px-3 py-2">
                              <a class="card-title textB pkgName font-weight-bold mb-0 text-dark">{{package.package_name}}</a>
                              <!-- <a class="d-block">
                                 <div class="mb-1 d-flex align-items-center textS1 text-color">
                                    <i class="icon flaticon-clock-circular-outline mr-2 textS1"></i> {{package.package_nights}}
                                    Nights / {{(package.package_nights+1)}} Days
                                 </div>
                              </a> -->
                              <div>
                                 <span class="d-flex flexDirectionRC text-black mb-0 font-weight-bold price">
                                    <div>
                                       <small class="d-block mr-2 textS position-relative rack">₹ {{package.rack_rate}} </small>
                                    </div>
                                    <div>
                                       <span>₹ {{package.display_rate}} </span>
                                       <small class="font-size-12 pn">Per Person</small>
                                    </div>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </a>
                  </div>
               </drag-scroll>
              <button mat-raised-button (click)="moveLeft1()" class="arrow prev d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_left</mat-icon></button>
              <button mat-raised-button (click)="moveRight1()" class="arrow next d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_right</mat-icon></button>
            </div>
               <!-- <div class="row mt-4">
                  <div class="col-md-12 text-center">
                     <button mat-raised-button color="accent" class="pBtn">More Packages</button>
                  </div>
               </div> -->
      </div>
   </section>
   <!-- <section class="tabs-block pt-5 pb-3">
      <div class="container">
         <div class="flex-center-between">
           <div class="sectionTitle pb-2">
              <h2 class="font-weight-bold text-black mb-0 text-lh-sm headingTitle text-left">Romantic Gateways</h2>
              <p class="text-color text-left">We have the best Honeymoon packages for your Holidays.</p>
              </div>   
              <a href="#"
                 class="text-primary textS">View All
              <i class="flaticon-right-arrow ml-2"></i>
              </a>
           </div>
               <div class="row mt-4">
                  <div class="col-md-12 text-center">
                     <button mat-raised-button color="accent" class="pBtn">More Packages</button>
                  </div>
               </div>
      </div>
   </section> -->
    <!-- <section>
      <div class="destinantion-block pt-3 pb-5">
         <div class="container position-relative">
            <div class="text-black flex-center-between">
               <div class="sectionTitle">
                  <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">Himachal Tour Package
                  </h2>
                  <p class="text-color">Checkout most trending locations to visit</p>
               </div>
               <a href="#"
                  class="text-primary textS">View All
               <i class="flaticon-right-arrow ml-2"></i>
               </a>
            </div>
            <drag-scroll #nav class="tours-items products items">
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649496027613.2-swimingpool_view.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649065490205.6-andaman01.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649831671202-ladakhimage.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649496027613.2-swimingpool_view.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649065490205.6-andaman01.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649831671202-ladakhimage.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649496027613.2-swimingpool_view.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649065490205.6-andaman01.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649831671202-ladakhimage.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649496027613.2-swimingpool_view.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649065490205.6-andaman01.jpg" />
               </div>
               <div drag-scroll-item class="item">
                  <img src="http://tripzolo.online/uploads/sliders/1649831671202-ladakhimage.jpg" />
               </div>
             </drag-scroll>
             <button mat-raised-button (click)="moveLeft()" class="arrows prev d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_left</mat-icon></button>
             <button mat-raised-button (click)="moveRight()" class="arrows next d-flex flex-content-center"><mat-icon class="icon text-dark">keyboard_arrow_right</mat-icon></button> 
             
         </div>
      </div>
    </section> -->
    
    <section class="icon-block-center icon-center-v1 pt-5 pb-5">
      <div class="container">
         <!-- Title -->
         <div class="position-relative pb-3 mt-3">
            <span class="uptitle">Categories</span>
            <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">Holiday by Themes</h2>
            <p class="text-color">Choose your vacation wisely</p>
            <div style="position: absolute;top: -222px;left: -312px;opacity: .4;z-index: 0;">
               <img src="../../../assets/img/color-spot.png" style="filter: hue-rotate(126deg);">
            </div>
            <div style="position: absolute;top: -69px;right: -77px;opacity: .5;z-index: 0;">
               <img src="../../../assets/img/color-spot.png">
            </div>
         </div>
         <!-- End Title -->
         <!-- Features -->
         <div class="mb-2">
            <div class="row categ flex-center-between">
               <!-- Icon Block -->
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/camping-tent.png">
                     </div>
                     <div class="font-weight-medium">Camp</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/mountain.png">
                     </div>
                     <div class="font-weight-medium">Hill Station</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/beach.png">
                     </div>
                     <div class="font-weight-medium">Beach</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/forest.png">
                     </div>
                     <div class="font-weight-medium">Wildlife</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/temple.png">
                     </div>
                     <div class="font-weight-medium">Pilgrimage</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/adventure.png">
                     </div>
                     <div class="font-weight-medium">Adventures</div>
                  </a>
               </div>
               <div class="item">
                  <a href="#" class="flex-center-between">
                     <div class="avt">
                        <img src="../../../assets/img/icons/heritage.png">
                     </div>
                     <div class="font-weight-medium">Heritage</div>
                  </a>
               </div>
               <!-- End Icon Block -->
            </div>
         </div>
         <!-- End Features -->
      </div>
 </section>
    <section class="pt-3 pb-5">
        <div class="container">
           <div class="topDeal">
              <div class="d-flex flex-center-between sectionTitle">
                 <div>
                     <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">
                        Top Deals
                     </h2>
                     <p>Most exciting offers awaits you.</p>
                  </div>
                 <a href="#" mat-flat-button class="text-primary textS">View All
                 <i class="flaticon-right-arrow ml-2"></i>
                 </a>
              </div>
              <div class="offer-items">
                 <div class="item deal1">
                    <img src="./../../../assets/img/deals/deals1.jpg" class="dealImg">
                    <div class="dealContent">
                       <h6 class="card-subtitle  text-block">30% OFF on Honeymoon Package</h6>
                       <p class="card-text">Use code : <span class="dcode">OSAHAN</span> </p>
                    </div>
                 </div>
                 <div class="item deal2">
                    <img src="./../../../assets/img/deals/deals2.jpg" class="dealImg">
                    <div class="dealContent">
                       <h6 class="card-subtitle text-block">Get 10% OFF on Flight Booking</h6>
                       <p class="card-text">Use code : <span class="dcode">EAT730</span> </p>
                    </div>
                 </div>
                 <div class="item deal3">
                    <img src="./../../../assets/img/deals/deals3.jpg" class="dealImg">
                    <div class="dealContent">
                       <h6 class="card-subtitle text-block">Get 10% OFF on Flight Booking</h6>
                       <p class="card-text">Use code : <span class="dcode">EAT730</span> </p>
                    </div>
                 </div>
              </div>
           </div>
           <section *ngIf="processSection" id="scrl"
              class="mx-auto p-3 mt-4 process animate__animated animate__backInRight">
              <div class="row">
                 <div class="col-md-12">
                    <h2
                       class="section-title text-white font-size-30 font-weight-bold mb-0 d-flex flex-center-between">
                       <span>Our Work Process</span>
                       <mat-icon (click)=closeProcess($event)>close</mat-icon>
                    </h2>
                    <p class="text-white">Follow these steps and get your perfect booking. </p>
                 </div>
              </div>
              <div class="row pb-3">
                 <div class="col-md-12">
                    <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                       <div class="work-process-icon-wrap pBtn rounded">
                          <mat-icon class="icon">search</mat-icon>
                          <span class="process-step bg-pcolor text-pcolor shadow-sm">1</span>
                       </div>
                       <span class="work-process-divider">
                          <mat-icon class="arrow ">send</mat-icon>
                       </span>
                       <div class="textS mt-4">
                          <h5 class="text-white">Search Destination / City</h5>
                          <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                             services. 
                          </p>
                       </div>
                    </div>
                    <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                       <div class="work-process-icon-wrap pBtn rounded">
                          <mat-icon class="icon">date_range</mat-icon>
                          <span class="process-step bg-pcolor text-pcolor shadow-sm">2</span>
                       </div>
                       <span class="work-process-divider">
                          <mat-icon class="arrow ">send</mat-icon>
                       </span>
                       <div class="textS mt-4">
                          <h5 class="text-white">Select Travel Date</h5>
                          <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                             services. 
                          </p>
                       </div>
                    </div>
                    <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                       <div class="work-process-icon-wrap pBtn rounded">
                          <mat-icon class="icon">view_list</mat-icon>
                          <span class="process-step bg-pcolor text-pcolor shadow-sm">3</span>
                       </div>
                       <span class="work-process-divider">
                          <mat-icon class="arrow ">send</mat-icon>
                       </span>
                       <div class="textS mt-4">
                          <h5 class="text-white">Choose Pakage, Hotel, etc</h5>
                          <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                             services. 
                          </p>
                       </div>
                    </div>
                    <div class="single-work-process col-md-3 col-sm-6 col-6 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                       <div class="work-process-icon-wrap color-4-bg rounded">
                          <mat-icon class="icon">done</mat-icon>
                          <span class="process-step complete text-pcolor shadow-sm">4</span>
                       </div>
                       <span class="work-process-divider">
                          <mat-icon class="arrow ">send</mat-icon>
                       </span>
                       <div class="textS mt-4">
                          <h5 class="text-white">Book Now !</h5>
                          <p class="textS mb-0 text-gray-4">Compellingly harness reliable methodologies and orthogonal web
                             services. 
                          </p>
                       </div>
                    </div>
                 </div>
              </div>
           </section>
           <!--   <section class="mx-auto pt-5 mb-4">
              <div class="row">
                  <div align="center" class="col-md-6 order2">
                    <img class="serv mHide" src="./../../../assets/img/service.png">
                  </div>
              
                  <div class="col-md-6 order1">
                    <h3 class="font-weight-bold font-size-28"><span class="bigLetter">A</span>bout TRIP<span class="dark-pink">ZOLO</span></h3>
                    <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches and the area’s tropical spice plantations.</p>
                    <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches.</p>
                    <p class="text-color text-justify">Goa is a state in western India with coastlines stretching along the Arabian Sea. Its long history as a Portuguese colony prior to 1961 is evident in its preserved 17th-century churches.</p>
                    <div class="d-flex cmyk">
                        <button mat-raised-button color="accent" class="pBtn">Read More</button>
                        <span class="float-right pushRight">
                    <span class="g"></span>
                    <span class="b"></span>
                    <span class="p"></span>
                    </span>
                    </div>
                  </div>
              </div>
              </section> -->
        </div>
     </section>
     <div class="icon-block-center icon-center-v1 wcu-bg ">
      <div class=" wcu-gradient-overlay text-center space-1">
      <div class="container text-center space-1">
          <!-- Title -->
          <div class="w-md-80 w-lg-50 text-center position-relative mx-md-auto pb-3 mt-3">
              <h2 class="section-title text-white font-size-24 font-weight-bold">Why Choose Us</h2>
          </div>
          <!-- End Title -->

          <!-- Features -->
          <div class="mb-2">
              <div class="row wcu">
                  <!-- Icon Block -->
                  <div class="col-md-4 item">
                      <i class="flaticon-price text-white font-size-70 "></i>
                      <div>
                      <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Competitive Pricing</a></h5>
                      <p class="text-white px-xl-2 px-uw-7">With 500+ suppliers and the purchasing power of 300 million members, mytravel.com can save you more!</p>
                  </div>
                  </div>
                  <!-- End Icon Block -->

                  <!-- Icon Block -->
                  <div class="col-md-4 item">
                      <i class="flaticon-medal text-white font-size-70 "></i>
                      <div>
                      <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Award-Winning Service</a></h5>
                      <p class="text-white px-xl-2 px-uw-7">Travel worry-free knowing that we're here if you needus, 24 hours a day</p>
                  </div>
                  </div>
                  <!-- End Icon Block -->

                  <!-- Icon Block -->
                  <div class="col-md-4 item">
                      <i class="flaticon-global-1 text-white font-size-70 "></i>
                      <div>
                      <h5 class="font-size-20 text-white font-weight-bold mb-2"><a href="#">Worldwide Coverage</a></h5>
                      <p class="text-white px-xl-2 px-uw-7">Over 1,200,000 hotels in more than 200 countries and regions & flights to over 5,000 cities</p>
                  </div>
                  </div>
                  <!-- End Icon Block -->
              </div>
          </div>
          <!-- End Features -->
      </div>
  </div>
  </div>
    <!-- Icon Block v1 -->
    <!-- <div class="icon-block-center icon-center-v1 wcu-bg pt-0">
       <div class="wcu-gradient-overlay text-center space-1">
          <div class="container">
             <div class="w-md-80 w-lg-50 text-center position-relative mx-md-auto pb-3 mt-3">
                <h2 class="section-title text-white font-size-30 font-weight-bold">Why Choose Us</h2>
             </div>
             <div class="mb-2">
                <div class="row wcu">
                   <div class="col-md-4 item">
                      <i class="flaticon-price text-white font-size-70 "></i>
                      <div>
                         <h5 class="font-size-20 text-white font-weight-bold mb-2"><a>Competitive Pricing</a>
                         </h5>
                         <p class="text-white px-xl-2 px-uw-7">With 500+ suppliers and the purchasing power of
                            300 million members, mytravel.com can save you more!
                         </p>
                      </div>
                   </div>
                   <div class="col-md-4 item">
                      <i class="flaticon-medal text-white font-size-70 "></i>
                      <div>
                         <h5 class="font-size-20 text-white font-weight-bold mb-2"><a>Award-Winning Service</a>
                         </h5>
                         <p class="text-white px-xl-2 px-uw-7">Travel worry-free knowing that we're here if you
                            needus, 24 hours a day
                         </p>
                      </div>
                   </div>
                   <div class="col-md-4 item">
                      <i class="flaticon-global-1 text-white font-size-70 "></i>
                      <div>
                         <h5 class="font-size-20 text-white font-weight-bold mb-2"><a>Worldwide Coverage</a></h5>
                         <p class="text-white px-xl-2 px-uw-7">Over 1,200,000 hotels in more than 200 countries
                            and regions & flights to over 5,000 cities
                         </p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div> -->
    <!-- End Icon Block v1 -->
    <!-- Product Cards -->
    <!-- <section class="product-card-block pt-7 pb-5">
       <div class="container">
          <div class="text-black sectionTitle">
             <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">Top Selling Packages
             </h2>
          </div>
          <div class="d-flex flex-center-between overflowY tours-items">
             <div class="col-md-3 col-xl-3 col-sm-5 col-7 mb-2" *ngFor="let package of top_packages">
                <div class="cardItem h-100">
                   <div class="position-relative mb-2">
                      <a class="d-block gradient-overlay-half-bg-gradient-v5">
                      <img class="card-img-top pkg-img" src="{{package.img_url}}{{package.sliders[0].path}}" alt="img">
                      </a>
                      <div class="position-absolute top-0 left-0 pt-3 pl-3">
                         <span
                            class="badge badge-pill text-white bg-pcolor px-4 py-2 font-size-14 font-weight-normal">Popular</span>
                      </div>
                      <div class="position-absolute bottom__30 left-0 right-0">
                         <div class="pb-2">
                            <span
                               class="badge badge-pill bg-white text-primary px-4 py-2 font-size-14 font-weight-normal">
                               <div class="tag">
                                   <mat-icon>family_restroom</mat-icon> <span>Family</span>
                               </div><span class="text-black p-1 views"><i class="fa fa-eye"></i> 1.2k</span>
                               </span>
                         </div>
                      </div>
                   </div>
                   <div class="card-body pcontent px-3 py-3">
                      <a class="card-title font-size-16 pkgName font-weight-bold mb-0 text-dark">{{package.package_name}}</a>
                      <a class="d-block">
                         <div class="mb-1 d-flex align-items-center textS1 text-color">
                            <i class="icon flaticon-clock-circular-outline mr-2 textS1"></i> {{package.package_nights}}
                            Nights / {{(package.package_nights+1)}} Days
                         </div>
                      </a>
                      <div class="px-2 pb-2 pt-2">
                         <h2 class="h5 text-black mb-0 font-weight-bold price"><small
                            class="mr-1 font-size-14">Starts @ </small>₹ {{package.display_rate}} <small
                            class="font-size-12 pn">Per Person</small></h2>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section> -->
    <!-- End Product Cards -->
    <!-- <section class="pt-5 pb-5">
       <div class="container">
          <div class="row">
             <div class="col-md-6">
                <img src="../../../assets/img/way.jpg" class="img-fluid">
             </div>
             <div class="col-md-6">
               <div class="text-black flex-center-between sectionTitle">
                  <div>
                    <span class="uptitle">FAQ</span>
                    <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">Frequently Asked Questions</h2>
                 </div>
               </div>
               <div>
                  <div>
                     <h6>What is the best time to travel to India?</h6>
                     <p class="textS">The best time to travel to India depends on the places a tourist would like to visit in the country. However, it is also safe to say that the winter season (October - March) is mostly preferred by the tourists while visiting India.</p>
                  </div>
                  <div>
                     <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h6>
                     <p class="textS">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quaerat asperiores nemo vitae! Aspernatur quibusdam sunt a consectetur beatae exercitationem fugiat quisquam odit nisi alias? Quos quidem dolor in dicta.</p>
                  </div>
                  <div>
                     <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h6>
                     <p class="textS">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quaerat asperiores nemo vitae! Aspernatur quibusdam sunt a consectetur beatae exercitationem fugiat quisquam odit nisi alias? Quos quidem dolor in dicta.</p>
                  </div>
               </div>
             </div>
          </div>
       </div>
    </section> -->
    <!-- Destinantion v1 -->
    <div class="destinantion-block pt-5 pb-5">
       <div class="container position-relative">
          <div class="text-black flex-center-between sectionTitle">
             <div>
               <span class="uptitle">Trending Destinations</span>
               <h2 class="font-weight-bold text-black headingTitle mb-0 text-lh-sm">Explore Top Destinations</h2>
            </div>
             <a [routerLink]="['/tour/tour-list',search_date,'All']" target="_blank"
                class="text-primary textS">View All
             <i class="flaticon-right-arrow ml-2"></i>
             </a>
          </div>
          <!--
             <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-4 mt-4">
                 <h2 class="section-title text-gray-color font-size-30 font-weight-bold mb-0">Top Trending <span class="text-black">Destination</span></h2>
             </div> -->
          <div class="tours-items">
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1649496027613.2-swimingpool_view.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Goa']" target="_blank">Goa</a>
                </div>
             </div>
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1649065490205.6-andaman01.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Andaman and Nicobar Islands']" target="_blank">Andaman</a>
                </div>
             </div>
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1649831671202-ladakhimage.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Ladakh']" target="_blank">Ladakh</a>
                </div>
             </div>
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1649918479821.7-bandhavgarhnationalpark.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Madhya Pradesh']" target="_blank">Madhya Pradesh</a>
                </div>
             </div>
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1650358596969.9-dharamshala.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Himachal Pradesh']" target="_blank">Himachal</a>
                </div>
             </div>
             <div class="item">
                <img src="http://tripzolo.online/uploads/sliders/1650442421677.3-dallake.jpg" class="destinations">
                <div class="locationName">
                   <a mat-raised-button [routerLink]="['/tour/tour-list',search_date,'Jammu and Kashmir']" target="_blank">Jammu Kashmir</a>
                </div>
             </div>
             <!-- <div>
                <i class="flaticon-left-arrow scrlL"></i>
                <i class="flaticon-right-arrow scrlR"></i>
             </div> -->
          </div>
       </div>
    </div>
    <!-- End Destinantion v1 -->
    <!-- Deal Carousel v1 -->
    <!-- <div class="deals-carousel-block deals-carousel-v1 ">
       <div class="container space-1">
           <h2 class="section-title text-black text-center mb-5 mt-3">Today's <span class="text-black">Top Deals</span>
           </h2>
           <div class="row mb-4 pb-2">
               <div class="col-md-4">
                   <div class="card offer-card shadow-sm offer-service">
                       <div class="card-body deals deals1">
                           <div class="">
                               <img src="./../../../assets/img/300x230/img3.jpg" alt="img"
                                   class="card-img-top dealsImg">
                           </div>
                           <div class="dealContent">
                               <h6 class="card-subtitle mb-2 text-block">30% OFF on Honeymoon Package</h6>
                               <p class="card-text">Use code OSAHAN &amp; get 50% off on your first osahan order on
                                   Website and Mobile site. Maximum discount: $200</p>
                               <a href="#" class="card-link">COPY CODE</a>
                               <a href="#" class="card-link">KNOW MORE</a>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="col-md-4">
                   <div class="card offer-card shadow-sm offer-service">
                       <div class="card-body  deals deals2">
                           <div class="">
                               <img src="./../../../assets/img/300x230/img3.jpg" alt="img"
                                   class="card-img-top dealsImg">
                           </div>
                           <div class="dealContent">
                               <h6 class="card-subtitle mb-2 text-block">Get 50% OFF on Hotel Booking</h6>
                               <p class="card-text">Use code EAT730 &amp; get 50% off on your first osahan order on
                                   Website and Mobile site. Maximum discount: $600</p>
                               <a href="#" class="card-link">COPY CODE</a>
                               <a href="#" class="card-link">KNOW MORE</a>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="col-md-4">
                   <div class="card offer-card shadow-sm offer-service">
                       <div class="card-body  deals deals3">
                           <div class="">
                               <img src="./../../../assets/img/300x230/img3.jpg" alt="img"
                                   class="card-img-top dealsImg">
                           </div>
                           <div class="dealContent">
                               <h6 class="card-subtitle mb-2 text-block">Get 10% OFF on Flight Booking</h6>
                               <p class="card-text">Use code EAT730 &amp; get 50% off on your first osahan order on
                                   Website and Mobile site. Maximum discount: $600</p>
                               <a href="#" class="card-link">COPY CODE</a>
                               <a href="#" class="card-link">KNOW MORE</a>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <!-- End Deal Carousel v1 -->
    <!-- Clients v1 -->
    <!-- <div class="clients-block clients-v1 border-bottom border-color-8">
       <div class="container space-1">
       <h2 class="section-title text-black text-center mb-5 mt-3">ACCREDITATION & MEMBER OF</h2>
           <div class="row justify-content-between pb-lg-1 members text-center text-md-left">
               <div class="col-md-2 mb-5">
                   <img class="img-fluid member-img" src="../../assets/img/iata.png" alt="Image Description">
               </div>
               <div class="col-md-2 col-md mb-5">
                   <img class="img-fluid member-img" src="../../assets/img/iato.png" alt="Image Description">
               </div>
               <div class="col-md-2 col-md mb-5">
                   <img class="img-fluid member-img" src="../../assets/img/tafi.png" alt="Image Description">
               </div>
               <div class="col-md-2 col-md mb-5">
                   <img class="img-fluid member-img" src="../../assets/img/taai.png" alt="Image Description">
               </div>
               <div class="col-md-2 col-md mb-md-5">
                   <img class="img-fluid member-img" src="../../assets/img/adtoi.png" alt="Image Description">
               </div>
           </div>
       </div>
       </div> -->
    <!-- End Clients v1 -->
    <div class="ourProcess">
       <span class="title" (click)=toggleProcess()>How To Book</span>
    </div>
 </main>