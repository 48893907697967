import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MiceComponent } from './landing-pages/mice/mice.component';
import { TourHomeComponent } from './tours/tour-home/tour-home.component';
import { ResetPasswordComponent } from './utils/reset-password/reset-password.component';

const routes: Routes = [
    {path: '', component: TourHomeComponent},
    {path: 'tour', loadChildren:() => import('./tours/tours.module').then(m => m.ToursModule)},
    {path: 'hotel', loadChildren:() => import('./hotels/hotels.module').then(m => m.HotelsModule)},
    {path: 'flight', loadChildren:() => import('./flights/flights.module').then(m => m.FlightsModule)},
    {path: 'my-account', loadChildren:() => import('./my-account/my-account.module').then(m => m.MyAccountModule), canActivate:[AuthGuard]},
    {path: 'pages', loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule)},
    {path: 'forgot-password/:secure_key', component:ResetPasswordComponent},
    {path: 'mice', component: MiceComponent},
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
