<div class="d-block position-relative text-center align-items-center">
    <img src="../../assets/img/tripzolo-logo.png" style="height:42px">
    <mat-icon mat-dialog-close class="closeDialog">close</mat-icon>
  </div>
  <div class="align-items-center text-black text-center mt-2">
  <a href="tel:1234567890" class="headingTitle flexDirectionRC justify-content-center text-pcolor"><span class="textB text-color pr-3"><mat-icon>support_agent</mat-icon> Speak to an Expert :</span>
     +91 123 456 7890</a>
  </div>
  <div class="d-block text-center">or</div>
  <p class="textS">Please share the below details, our destination expert will contact you and customize your trip.</p>
<form class="example-form">
    <h4 class="textB mb-0">Persoanl Information</h4>
      <div class="row">
          <div class="col-md-6">
              <mat-form-field class="example-full-width">
                  <mat-label>Full Name</mat-label>
                  <input matInput>
                </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <mat-label>Email Id</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                       placeholder="Ex. pat@example.com">
                <mat-hint></mat-hint>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-4">
              <mat-form-field class="example-full-width">
                  <mat-label>Contact No.</mat-label>
                  <span matPrefix>+91 &nbsp;</span>
                  <input type="tel" matInput>
                </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
                <mat-label>City</mat-label>
                <mat-select>
                    <mat-option *ngFor="let count of counts" [value]="count.value">
                      {{count.viewValue}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col-md-4"></div>
      </div>
      <hr>
      <h4 class="textB mb-0">Travel Information</h4>
      <div class="row">
          <div class="col-md-4">
              <mat-form-field appearance="" class="example-full-width pkglist traveldate" color="accent">
                  <mat-label>Travel date</mat-label>
                  <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly (dateChange)="searchDate($event)" [min]="minDate" [value]="tourModifySearch.searchDate">
                  <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="" class="example-full-width pkglist traveldate" color="accent">
                <mat-label>Return date</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly (dateChange)="searchDate($event)" [min]="minDate" [value]="tourModifySearch.searchDate">
                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>
        <div class="col-md-4 align-self-center">
            <span class="nights textS font-weight-medium">3 Nights / 4 Days</span>
        </div>
        <div class="col-md-4 align-self-center">
            <span class="textS font-weight-bold">Destinations :</span>
        </div>
        <div class="col-md-4" *ngFor="let dest of destination; let i = index">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Enter Destination" [(ngModel)]="destinationname"  name="destinationname">
            </mat-form-field>
        </div>
        <div class="absoluteTopRightP">
            <button mat-icon-button ><mat-icon>delete</mat-icon></button>
          </div>
        <div class="col-md-4 align-self-center">
            <a mat-button class="textS" color="accent" (click)="addDestinations()">+ Add Destination</a>
        </div>
          <mat-divider></mat-divider>
          <div class="row">
          <div class="col-md-4">
              <mat-form-field>
                  <mat-label>No Of Rooms</mat-label>
                  <mat-select>
                      <mat-option *ngFor="let count of counts" [value]="count.value">
                        {{count.viewValue}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
          </div>
          <div class="col-md-4">
              <mat-form-field>
                  <mat-label>No Of Adults</mat-label>
                  <mat-select>
                      <mat-option *ngFor="let count of counts" [value]="count.value">
                        {{count.viewValue}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
          </div>
          <div class="col-md-4">
              <mat-form-field>
                  <mat-label>No Of Child</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let count of counts" [value]="count.value">
                      {{count.viewValue}}
                    </mat-option>
                  </mat-select>
                  <mat-hint>Below 11 Years</mat-hint>
                </mat-form-field>
          </div>
          </div>
      </div>
      <hr>
      <h4 class="textB mb-0">Holiday Preference</h4>
      <div class="row">
          <div class="col-md-12">
            <section class="example-section">
                <mat-checkbox class="mr-3">Beach</mat-checkbox>
                <mat-checkbox class="mr-3">Resort</mat-checkbox>
                <mat-checkbox class="mr-3">Mountain</mat-checkbox>
                <mat-checkbox class="mr-3">Nature</mat-checkbox>
                <mat-checkbox class="mr-3">Activities</mat-checkbox>
                <mat-checkbox class="mr-3">Water Sports</mat-checkbox>
              </section>
          </div>
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <mat-label>Message</mat-label>
                  <textarea matInput></textarea>
                </mat-form-field>
          </div>
      </div>
  </form>


      <button mat-raised-button [mat-dialog-close]="true" color="accent" cdkFocusInitial>Submit</button>

