import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-markup',
  templateUrl: './markup.component.html',
  styleUrls: ['./markup.component.css']
})
export class MarkupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
