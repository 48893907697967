<div class="rounded p-3 mb-5 bgc-primary">
  <h6 class="font-size-16 font-weight-bold text-white mb-3 ">Why Book With Us?</h6>
  <div class="d-flex align-items-center mb-3">
     <i class="flaticon-star font-size-25 text-white mr-3"></i>
     <h6 class="mb-0 font-size-14 text-white">
        <a href="#">No-hassle best price guarantee</a>
     </h6>
  </div>
  <div class="d-flex align-items-center mb-3">
     <i class="flaticon-support font-size-25 text-white mr-3"></i>
     <h6 class="mb-0 font-size-14 text-white">
        <a href="#">Customer care available 24/7</a>
     </h6>
  </div>
  <div class="d-flex align-items-center mb-3">
     <i class="flaticon-favorites-button font-size-25 text-white mr-3"></i>
     <h6 class="mb-0 font-size-14 text-white">
        <a href="#">Hand-picked Tours &amp; Activities</a>
     </h6>
  </div>
  <div class="d-flex align-items-center mb-0">
     <i class="flaticon-airplane font-size-25 text-white mr-3"></i>
     <h6 class="mb-0 font-size-14 text-white">
        <a href="#">Free Travel Insurance</a>
     </h6>
  </div>
</div>
