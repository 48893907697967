<mat-sidenav-container class="sideNav">
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (sidenavClose)="sidenav.close()" (click)="sidenav.toggle()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-container class="sideNav">
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </main>
    <app-footer></app-footer>
    <app-scroll-top></app-scroll-top>
  </mat-sidenav-container>
</mat-sidenav-container>
