<header *ngIf="visible_header" id="header"
  class="u-header u-header--abs-top u-header--white-nav-links-xl u-header--bg-transparent u-header--show-hide border-bottom border-xl-bottom-0 border-color-white u-header__shadow-on-show-hide"
  data-header-fix-moment="500" data-header-fix-effect="slide">
  <div class="u-header__section u-header__shadow-on-show-hide">
    <div id="logoAndNav" class="container-fluid py-1 py-xl-2">
      <nav class="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space">
        <button mat-icon-button class="example-icon sidenav-toggle" aria-label="Example icon-button with menu icon"
          (click)="onToggleSidenav()">
          <mat-icon class="text-white">menu</mat-icon>
        </button>
        <a [routerLink]="['/']" [attr.aria-label]="contact_details.CONTACT_APP_NAME">
          <img style="height: 42px;" src="../../assets/img/tripzolo-logo-white.png">
        </a>
        <ul class="navbar-nav dSearchIcon">
          <mat-icon class="searchIc">search</mat-icon>
          <form id="demo-2" class="example-form">
            <mat-form-field class="headerSearch" floatLabel="never">
              <!-- <mat-icon class="text-white">search</mat-icon> -->
              <input matInput
                type="text"
                (keyup)="onStateCitySearch($event)"
                (paste)="onStateCitySearch($event)"
                placeholder="Tour Packages"
                aria-label="Number"
                [matAutocomplete]="search"
              >
              <mat-autocomplete #search="matAutocomplete">
                <mat-optgroup label="STATE" class="suggestions">
                  <mat-option *ngFor="let state of places && places.states" (onSelectionChange)="onSelectSearch(state, 'state')" [value]="state">
                  {{state}}
                  </mat-option>
                </mat-optgroup>
                <mat-optgroup label="LOCATION" class="suggestions">
                  <mat-option *ngFor="let location of places && places.locations" (onSelectionChange)="onSelectSearch(location, 'location')" [value]="location">
                  {{location}}
                  </mat-option>
                </mat-optgroup>
                <mat-optgroup label="PACKAGES">
                  <mat-option *ngFor="let package of places && places.packages" (onSelectionChange)="onSelectSearch(package.id, 'package')" [value]="package.package_name">
                  {{package.package_name}}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </ul>
        <div id="navBar"
          class="navbar-collapse u-header__navbar-collapse collapse order-2 order-xl-0 pt-4 p-xl-0 position-relative mx-n3 mx-xl-0">
          <a mat-button class="menus" [routerLink]="['/']" routerLinkActive="router-link-active"> Plan Your Trip !</a>
          <!-- <a mat-button class="menus" [routerLink]="['/flight']" routerLinkActive="router-link-active"> Flight</a> -->
          <a mat-button class="menus" [routerLink]="['/hotel']" routerLinkActive="router-link-active"> Hotels</a>
          <a mat-button class="menus" [routerLink]="['/mice']" routerLinkActive="router-link-active"> MICE</a>
          <!-- <a mat-button class="menus" [routerLink]="['/']" routerLinkActive="router-link-active"> Holidays</a> -->
          <!-- <a mat-button class="menus" [routerLink]="['/hotel']" routerLinkActive="router-link-active"> Hotels</a>
          <a mat-button class="menus" [routerLink]="['/flight']" routerLinkActive="router-link-active"> Flights</a> -->
        </div>
        <div class="hTopSearch">
          <mat-icon (click)="openSearchBoxDialog()" class="wsearchIcon">search</mat-icon>
        </div>
        <a mat-button class="menus mHide"  [routerLink]="['/pages/about']" routerLinkActive="router-link-active"> About us</a>
        <a mat-button class="menus mHide" [routerLink]="['/pages/contact-us']" routerLinkActive="router-link-active"> Contact Us</a>
        <a mat-button class="menus mHide" [matMenuTriggerFor]="menu" routerLinkActive="router-link-active">
          Help <mat-icon>arrow_drop_down</mat-icon>
          <!-- <mat-icon class="text-white">help</mat-icon> -->
          <mat-menu #menu="matMenu">
            <div class="text-color font-weight-medium px-2 pb-2 font-size-14">Need Help ?</div>
            <button mat-menu-item class="d-flex align-items-center">
              <img src="../../../assets/img/call-agent.png" class="pr-3">
              <div class="number">
                <span class="d-block text-color">Speak To Expert</span>
                <span class="font-weight-medium textM">{{contact_details.CONTACT_NUMBER}}</span>
              </div>
              </button>
            <button mat-menu-item>
              <img src="../../../assets/img/mail-us.png" class="pr-3">
              <span class="font-weight-medium">{{contact_details.CONTACT_EMAIL}}</span>
            </button>
            <button mat-menu-item>
              <img src="../../../assets/img/cancellation.png" class="pr-3">
              <span class="font-weight-medium">Booking Cancellation</span>
            </button>
          </mat-menu>
        </a>
        <button mat-button class="menus pr-0" *ngIf="!userLoggedIn" (click)="openLoginDialog()">
          <mat-icon>account_circle</mat-icon> <span class="userName text-white">Login or Create Accounts</span>
        </button>
        <button mat-button *ngIf="userLoggedIn" [matMenuTriggerFor]="beforeMenu" class="menus pr-0">
          <div *ngIf="loggedUserDetail && !loggedUserDetail.avatar" class="userN">{{loggedUserDetail && loggedUserDetail.user_name | slice:0:1 | uppercase }}</div>
          <img [src]="loggedUserDetail.avatar" *ngIf="loggedUserDetail && loggedUserDetail.avatar" class="userN">
          <span class="mHide">Hello, <span class="text-capitalize">{{loggedUserDetail && loggedUserDetail.user_name}}</span></span>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="after">
          <button mat-menu-item [routerLink]="['/my-account/profile']" routerLinkActive="router-link-active">
            <mat-icon>person</mat-icon> Profile
          </button>
          <button mat-menu-item [routerLink]="['/my-account/bookings']" routerLinkActive="router-link-active" >
            <mat-icon>subject</mat-icon> Bookings
          </button>
          <button mat-menu-item [routerLink]="['/my-account/favorites']" routerLinkActive="router-link-active" >
            <mat-icon>favorite</mat-icon> Favorites
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon> Logout
          </button>
        </mat-menu>
      </nav>
    </div>
  </div>
</header>


<header *ngIf="!visible_header" id="header"
  class="u-header  u-header--dark-nav-links-xl u-header--show-hide-xl u-header--static-xl mobile-nav"
  data-header-fix-moment="500" data-header-fix-effect="slide">

  <div class="u-header__section u-header__shadow-on-show-hide py-2 py-xl-0">
    <div id="logoAndNav" class="container-fluid py-xl-2__5 border-bottom-xl">
      <nav class="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space">
        <button mat-icon-button class="example-icon sidenav-toggle" aria-label="Example icon-button with menu icon"
          (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
        <a [routerLink]="['/']" [attr.aria-label]="contact_details.CONTACT_APP_NAME">
          <img style="height: 42px;" src="../../assets/img/tripzolo-logo.png">
        </a>
        <ul class="navbar-nav u-header__navbar-nav dSearchIcon">
          <mat-icon class="searchIc">search</mat-icon>
          <form id="demo-2" class="example-form">
            <mat-form-field class="headerSearch" floatLabel="never">
              <!-- <mat-label></mat-label> -->
              <!-- <mat-icon>search</mat-icon> -->
              <input
                matInput
                type="text"
                (keyup)="onStateCitySearch($event)"
                (paste)="onStateCitySearch($event)"
                placeholder="Tour Packages"
                aria-label="Number"
                [matAutocomplete]="search"
              >
                <mat-autocomplete #search="matAutocomplete">
                  <mat-optgroup label="STATE" class="suggestions">
                    <mat-option *ngFor="let state of places && places.states" (onSelectionChange)="onSelectSearch(state, 'state')" [value]="state">
                    {{state}}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup label="LOCATION" class="suggestions">
                    <mat-option *ngFor="let location of places && places.locations" (onSelectionChange)="onSelectSearch(location, 'location')" [value]="location">
                    {{location}}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup label="PACKAGES" class="suggestions">
                    <mat-option *ngFor="let package of places && places.packages" (onSelectionChange)="onSelectSearch(package.id, 'package')" [value]="package.package_name">
                    {{package.package_name}}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
          </form>
        </ul>
        <div id="navBar"
          class="navbar-collapse u-header__navbar-collapse collapse order-2 order-xl-0 pt-4 p-xl-0 position-relative">
          <a mat-button [routerLink]="['/']" routerLinkActive="router-link-active"> Holidays</a>
          <a mat-button [routerLink]="['/hotel']" routerLinkActive="router-link-active"> Hotels</a>
          <a mat-button [routerLink]="['/mice']" routerLinkActive="router-link-active"> MICE</a>
          <!-- <a mat-button [routerLink]="['/flight']" routerLinkActive="router-link-active"> Flights</a> -->
        </div>
        <div class="hTopSearch">
          <mat-icon (click)="openSearchBoxDialog()">search</mat-icon>
        </div>
        <div class="topHeaderNavbar hideMedium">
        <a mat-button class="mHide"  [routerLink]="['/pages/about']" routerLinkActive="router-link-active"> About us</a>
        <a mat-button class="mHide" [routerLink]="['/pages/contact-us']" routerLinkActive="router-link-active"> Contact Us</a>
        <a mat-button class="mHide" [matMenuTriggerFor]="menu" routerLinkActive="router-link-active">
          Help <mat-icon>arrow_drop_down</mat-icon>
          <mat-menu #menu="matMenu">
            <div class="text-color font-weight-medium px-2 pb-2 font-size-14">Need Help ?</div>
            <button mat-menu-item class="d-flex align-items-center">
              <img src="../../../assets/img/call-agent.png" class="pr-3">
              <div class="number">
                <span class="d-block text-color">Speak To Expert</span>
                <span class="font-weight-medium textM">{{contact_details.CONTACT_NUMBER}}</span>
              </div>
            </button>
            <button mat-menu-item>
              <img src="../../../assets/img/mail-us.png" class="pr-3">
              <span class="font-weight-medium">{{contact_details.CONTACT_EMAIL}}</span>
            </button>
            <button mat-menu-item>
              <img src="../../../assets/img/cancellation.png" class="pr-3">
              <span class="font-weight-medium">Booking Cancellation</span>
            </button>
          </mat-menu>
          <!-- <img src="../../../assets/img/need-support.png"> -->
        </a>
        <!-- <a mat-button class="mHide"  routerLinkActive="router-link-active"><mat-icon>call</mat-icon> <span class="mHide">{{contact_details.CONTACT_NUMBER}}</span> </a> -->
        </div>
        <button mat-button *ngIf="!userLoggedIn" (click)="openLoginDialog()" class="pr-0">
          <mat-icon color="primary">account_circle</mat-icon> <span class="userName">Login or Create Account</span>
        </button>
        <button mat-button *ngIf="userLoggedIn" [matMenuTriggerFor]="beforeMenu" class="pr-0">
          <div *ngIf="loggedUserDetail && !loggedUserDetail.avatar" class="userN">{{loggedUserDetail && loggedUserDetail.user_name | slice:0:1 | uppercase }}</div>
          <img [src]="loggedUserDetail.avatar" *ngIf="loggedUserDetail && loggedUserDetail.avatar" class="userN">
          <span class="mHide">Hello, <span class="text-capitalize">{{loggedUserDetail && loggedUserDetail.user_name}}</span></span>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="after">
          <button mat-menu-item [routerLink]="['/my-account/profile']" routerLinkActive="router-link-active">
            <mat-icon>person</mat-icon> Profile
          </button>
          <button mat-menu-item [routerLink]="['/my-account/bookings']" routerLinkActive="router-link-active">
            <mat-icon>subject</mat-icon> Bookings
          </button>
          <button mat-menu-item [routerLink]="['/my-account/favorites']" routerLinkActive="router-link-active" >
            <mat-icon>favorite</mat-icon> Favorites
          </button>
          <button mat-menu-item color="primary" (click)="logout()">
            <mat-icon>power_settings_new</mat-icon> Logout
          </button>
        </mat-menu>
      </nav>
    </div>
  </div>
</header>
