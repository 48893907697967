export const environment = {
  production: true,
  PACKAGE: 'package',
  HOTEL: 'hotel',
  FLIGHT: 'flight',

  CONTACT_NUMBER: '+91 7777 02 4800',
  CONTACT_EMAIL: 'contact@tripzolo.com',
  CONTACT_ADDRESS: 'D-212, Eastern business District, LBS Road, Bhandup West, Mumbai - 400078.',
  CONTACT_WEBSITE: 'tripzolo.com',
  CONTACT_APP_NAME: 'TripZolo'
};
