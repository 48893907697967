<mat-list>
  <div mat-subheader>
    <a [routerLink]="['/']" [attr.aria-label]="contact_details.CONTACT_APP_NAME" class="d-flex flex-center-between w-100">
      <img style="height: 34px;" src="../../assets/img/tripzolo-logo.png">
  </a>
  </div>
  <mat-divider class="mb-2"></mat-divider>
  <mat-list-item *ngFor="let link of links">
    <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
    <mat-icon mat-list-icon class="tz-sidebarIcon"><span class="sidebar {{link.img}}" [routerLink]="link.route" routerLinkActive="router-link-active"></span></mat-icon>
    <div mat-line [routerLink]="link.route" routerLinkActive="router-link-active" class="font-weight-bold">{{link.name}}</div>

  </mat-list-item>
</mat-list>
