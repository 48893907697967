import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/alert/snack-bar.service';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { EndPointService } from 'src/app/services/end-point.service';
import { register, login } from 'src/models/user';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-log-in-dialog',
  templateUrl: './log-in-dialog.component.html',
  styleUrls: ['./log-in-dialog.component.css']
})
export class LogInDialogComponent implements OnInit {
  hide = true;
  login = new login();
  register = new register();
  progress_loader = false;
  login_loader = false;

  constructor(
    private dialogRef: MatDialogRef<LogInDialogComponent>,
    private endPoint: EndPointService,
    public behaviourService: BehaviourService,
    public _snack_bar: SnackBarService,
    public dialog: MatDialog,
    private authService: SocialAuthService
  ) { }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      console.log(user);
     });
  }

  forgetPassword(){
    this.dialog.open(ForgetPasswordComponent, {width: '650px'});
  }
  userRegister(){
    this.progress_loader = true;
    this.endPoint.postData('api-user/register', this.register).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        this.progress_loader = false;
        localStorage.setItem('user-web-login', JSON.stringify(resp.data));
        sessionStorage.setItem('user-web-login', JSON.stringify(resp.data));
        this.dialogRef.close({data: true});
        this.behaviourService.setLogin(resp.data);
        this._snack_bar.success('User register successfully');
        return;
      }
      this._snack_bar.error(resp.message);
      this.progress_loader = false;

    },(error) => {
      this.progress_loader = false;
      this._snack_bar.error('Oops..! Error while creating user.');
    });
  }

  userLogin(){
    this.login_loader = true;
    this.endPoint.postData('api-user/login', this.login).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        this.login_loader = false;
        localStorage.setItem('user-web-login', JSON.stringify(resp.data));
        sessionStorage.setItem('user-web-login', JSON.stringify(resp.data));
        this.dialogRef.close({data: true});
        this.behaviourService.setLogin(resp.data);
        this._snack_bar.success('Login success');
        return;
      }
      this._snack_bar.error(resp.message);
      this.login_loader = false;
    },(error) => {
      this.login_loader = false;
      this._snack_bar.error('Oops..! Error while login user.');
    });
  }

  signInWithFB(): void {
    let window = this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    window.catch(resp =>{
      console.log(resp);
    });
  }

  signInWithGoogle(): void {
    let window = this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    window.catch(resp =>{
      console.log(resp);
    });
  }
}
