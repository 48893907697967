import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { EndPointService } from 'src/app/services/end-point.service';
import { tourModifySearch } from 'src/models/modify-search-model';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import * as _moment from "moment";

const moment =  _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MMMM DD, YYYY"
  },
  display: {
    dateInput: "MMMM DD, YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-tour-modify-search',
  templateUrl: './tour-modify-search.component.html',
  styleUrls: ['./tour-modify-search.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TourModifySearchComponent implements OnInit {
  tourModifySearch = new tourModifySearch();
  states = [];
  minDate = new Date(new Date().setDate(new Date().getDate()+2));
  $searchTermChanged: Subject<string> = new Subject<string>();
  places: any = [];
  date: any;
  destination_spinner: boolean = false;
  destination: any;
  filterForm: FormGroup;
  selecedDestination = '';
  modifyModal = false;
  hideHomeModify = true;

  constructor(
    private endPoint: EndPointService,
    private navCtrl: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    let search_date = this.route.snapshot.paramMap.get('search_date');
    this.selecedDestination = this.route.snapshot.paramMap.get('destination');
    this.selecedDestination = (this.selecedDestination === null) ? 'All' : this.selecedDestination;
    this.date = (search_date != null && search_date != undefined) ? Number(this.route.snapshot.paramMap.get('search_date')) : this.minDate.getTime();
    this.tourModifySearch.searchDate = this.dateFormater((search_date != null && search_date != undefined) ?new Date(Number(this.route.snapshot.paramMap.get('search_date'))) : this.minDate);
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  ngOnInit(): void {
    if(this.navCtrl.url === '/'){
      this.hideHomeModify = false;
      this.modifyModal = true;
    }
  }

  getDestination(){
    return this.route.snapshot.paramMap.get('destination');
  }

  dateFormater(date){
    return date.getFullYear()
    +'-'+
    (('0' + (date.getMonth() + 1)).slice(-2))
    +'-'+
    (('0' + (date.getDate())).slice(-2));
  }


  onStateCitySearch(event: any) {
    this.places = [];
    if (this.$searchTermChanged.observers.length === 0) {
        this.$searchTermChanged.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(term => {
              let search = {
                term: term
              }
              this.endPoint.getData('api-package/search/place',search).subscribe(resp => {
                if (resp.status && resp.message == 'success') {
                  this.places = resp.data;
                  this.destination_spinner = false;
                }
              });
            });
    }
    if(event.target.value.length >= 3){
      this.destination_spinner = true;
      this.$searchTermChanged.next(event.target.value);
    }
}

  selectedDestination(value) {
    this.selecedDestination = value;
  }
  searchDate(event){
    this.date = event.target.value._d.getTime();
  }

  filterSearch(){
    this.navCtrl.navigate(['/tour/tour-list/',this.date, this.selecedDestination]);
    this.modifyModal = this.hideHomeModify ? false : true;
  }

  toggleModify(){
    this.modifyModal = !this.modifyModal;
  }
  closeModify(){
    this.modifyModal = false;
  }
}
