import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-skeleton',
  templateUrl: './tour-skeleton.component.html',
  styleUrls: ['./tour-skeleton.component.css']
})
export class TourSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
