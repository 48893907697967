import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { LogInDialogComponent } from '../log-in-dialog/log-in-dialog.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EndPointService } from 'src/app/services/end-point.service';
import { environment } from 'src/environments/environment.prod';
import { SocialAuthService } from "angularx-social-login";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  $searchTerm: Subject<string> = new Subject<string>();
  visible_header: boolean = true;
  loginModal=false;
  hide = true;
  userLoggedIn = false;
  loggedUserDetail: any;
  contact_details = environment;
  places: any = [];

  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    public behaviourService: BehaviourService,
    private endPoint: EndPointService,
    private authService: SocialAuthService
  ) {
    router.events.subscribe(val => {
      this.visible_header = (this.location.path() !== "") ? false : true;

      this.loggedUserDetail = JSON.parse(localStorage.getItem('user-web-login'));
      if(typeof this.loggedUserDetail == 'object' && this.loggedUserDetail !== null){
        this.loggedUserDetail.avatar = this.loggedUserDetail.avatar;
      }
      // this.loggedUserDetail.avatar = JSON.parse(localStorage.getItem('user-web-login')).avatar;
    });
  }

  ngOnInit(): void {
    this.behaviourService.getLogin().subscribe(logged_in => {
      this.isUserLoggedIn();
    });
  }

  openSearchBoxDialog(){
    this.dialog.open(SearchBarComponent);
  }
  isUserLoggedIn(){
    this.loggedUserDetail = JSON.parse(localStorage.getItem('user-web-login'));
    if(typeof this.loggedUserDetail == 'object' && this.loggedUserDetail !== null){
      this.userLoggedIn = true;
    }
  }

  openLoginDialog(){
    let dialogResp = this.dialog.open(LogInDialogComponent);
    // dialogResp.beforeClosed().subscribe(resp => {
    //   this.userLoggedIn = (resp != undefined)? resp.data : false;
    //   if(this.userLoggedIn === true){
    //     this.loggedUserDetail = JSON.parse(localStorage.getItem('user-web-login'));
    //   }
    // })
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit()
  }

  openDialog(){
    this.loginModal = true;
  }
  openDialogClose(){
    this.loginModal = false;
  }

  logout(){
    localStorage.removeItem('user-web-login');
    sessionStorage.removeItem('user-web-login');
    this.userLoggedIn = false;
    this.behaviourService.setLogin(false);
    this.authService.signOut();
  }




  onStateCitySearch(event: any) {
    if (this.$searchTerm.observers.length === 0) {
        this.$searchTerm.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(term => {
              let search = {
                term: term
              }
              this.endPoint.getData('api/search',search).subscribe(resp => {
                if (resp.status && resp.message == 'success') {
                  this.places = resp.data;
                }
              });
            });
    }
    if(event.target.value.length >= 3){
      this.$searchTerm.next(event.target.value);
    }
  }

  onSelectSearch(value, key){
    let date = new Date(new Date().setDate(new Date().getDate()+4)).getTime();
    switch(key){
      case 'state':
        this.router.navigate(['/tour/tour-list/',date,value]);
      break;
      case 'location':
        this.router.navigate(['/tour/tour-list/',date,value]);
      break;
      case 'package':
        this.router.navigate(['/tour/tour-detail/',value,date, 'All']);
      break;
    }
  }
}
