import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PlanYourTripComponent } from '../dialogs/plan-your-trip/plan-your-trip.component';
import { EndPointService } from 'src/app/services/end-point.service';
import { SnackBarService } from 'src/app/services/alert/snack-bar.service';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-tour-home',
  templateUrl: './tour-home.component.html',
  styleUrls: ['./tour-home.component.css']
})
export class TourHomeComponent implements OnInit {
  processSection = false;
  packages = [];
  top_packages = [];
  search_date = new Date().setDate(new Date().getDate()+2);
  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
  @ViewChild('nav1', {read: DragScrollComponent}) ds1: DragScrollComponent;
  
  package_modes = [
    {icon: 'family_restroom',title: 'Family Tour', name: 'Family'},
    {icon: 'favorite',title: 'Honeymoon Packages' , name: 'Honeymoon'},
    {icon: 'groups',title: 'Group Tour', name: 'Group'},
  ]
  banner = [
    {'image': '../../../assets/img/rajasthan.jpg'}, 
    {'image': '../../../assets/img/ads2.jpg'},
    {'image': '../../../assets/img/ads3.jpg'}
  ];
  slides = [
    {'image': '../../../assets/img/ads1.jpg'}, 
    {'image': '../../../assets/img/ads2.jpg'},
    {'image': '../../../assets/img/ads3.jpg'},
    {'image': '../../../assets/img/ads4.jpg'}
    
  ];
  constructor(
    private router:Router,
    private dialog: MatDialog,
    private endPoint: EndPointService,
    private route: ActivatedRoute,
    public _snack_bar: SnackBarService
  ) {}

  
  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  ngAfterViewInit() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      this.ds.moveTo(0);
    }, 0);
  }
  moveLeft1() {
    this.ds1.moveLeft();
  }

  moveRight1() {
    this.ds1.moveRight();
  }

  moveTo1(index) {
    this.ds1.moveTo(index);
  }

  ngAfterViewInit1() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      this.ds1.moveTo(0);
    }, 0);
  }
  openPlanYourTrip(){
    this.dialog.open(PlanYourTripComponent, {width: '650px'});
  }
  ngOnInit(): void {
    this.getPackages(3, 'recommended');
    this.getPackages(0, 'top_deal');
  }
  toggleProcess(){
    this.processSection = !this.processSection;
  }
  closeProcess(){
    this.processSection = false;
  }
  // public closeProcess(event) {
  //     document.getElementById('scrl').classList.remove('animate__backInRight');
  //     document.getElementById('scrl').classList.add('animate__backOutRight');
  // }
  getTourList(){
    this.router.navigate(['/tour/tour-list/AN']);
    // let id = 1
    // this.router.navigate([]).then(result => {
    //   window.location.href = 'tour/tour-list/AN';
    // });
  }

  getPackages(package_type, type){
    this.packages = [];
  switch(package_type.index){
    case 0:
      package_type = 3
    break;
    case 1:
      package_type = 1
    break;
    case 2:
      package_type = 4
    break;
  }
    this.endPoint.getData('api-package/homes/'+package_type+'/'+type+'/packages', {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        // switch(type){
        //   case 'recommended'
        // }
        if(type == 'recommended'){
         this.packages = resp.data;
        }else{
          this.top_packages = resp.data;
        }
      }
    },(error) => {
      this._snack_bar.error('Oops :)\nError while fetching packages.');
    });
  }

}
