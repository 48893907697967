import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/alert/snack-bar.service';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgot_password = {
    email: ''
  }
  constructor(
    private router: Router,
    private endPoint: EndPointService,
    private route: ActivatedRoute,
    public _snack_bar: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  sendForgotPasswordMail(){
    this.endPoint.postData('api-user/forgot-password',this.forgot_password).subscribe(resp => {
      if (resp.status === true) {
        this._snack_bar.success(resp.message);
        return;
      }
      this._snack_bar.error(resp.message);
    },(error) => {
      this._snack_bar.error('Oops..! Error while forgot password.');
    });
  }
}
