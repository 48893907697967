// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PACKAGE: 'package',
  HOTEL: 'hotel',
  FLIGHT: 'flight',

  CONTACT_NUMBER: 7777024800,
  CONTACT_EMAIL: 'contact@tripzolo.com',
  CONTACT_ADDRESS: 'D-212, Eastern business District, LBS Road, Bhandup West, Mumbai - 400078.',
  CONTACT_WEBSITE: 'tripzolo.com',
  CONTACT_APP_NAME: 'TripZolo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
