<div class="d-flex align-items-center">
<mat-icon mat-dialog-close mat-dialog-close="false" class="mr-2">arrow_back</mat-icon>
  <div class="w-100">
    <mat-form-field appearance="fill" class="example-full-width headerSearch">
      <input matInput type="search" (keyup)="onStateCitySearch($event)" (paste)="onStateCitySearch($event)"
        placeholder="Hotels, Destination, Tour, etc.." aria-label="Number" autocomplete="off">
    </mat-form-field>
  </div>
</div>

<mat-list>
  <div mat-subheader *ngIf="places && places.states?.length > 0">STATE</div>
  <mat-list-item *ngFor="let state of places && places.states" (click)="onSelectSearch(state, 'state')">{{state}}
  </mat-list-item>
  <mat-divider *ngIf="places && places.locations?.length > 0"></mat-divider>

  <div mat-subheader *ngIf="places && places.locations?.length > 0">LOCATION</div>
  <mat-list-item *ngFor="let location of places && places.locations" (click)="onSelectSearch(location, 'location')">
    {{location}}</mat-list-item>
    <mat-divider *ngIf="places && places.packages?.length > 0"></mat-divider>

  <div mat-subheader *ngIf="places && places.packages?.length > 0">PACKAGES</div>
  <mat-list-item *ngFor="let package of places && places.packages" (click)="onSelectSearch(package.id, 'package')">
    {{package.package_name}}</mat-list-item>
</mat-list>
