import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EndPointService } from 'src/app/services/end-point.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  $searchTerm: Subject<string> = new Subject<string>();
  places: any = [];

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<SearchBarComponent>,
    // public dialog: MatDialog,
    // public behaviourService: BehaviourService,
    private endPoint: EndPointService
  ) { }

  ngOnInit(): void {
  }


  onStateCitySearch(event: any) {
    if (this.$searchTerm.observers.length === 0) {
        this.$searchTerm.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(term => {
              let search = {
                term: term
              }
              this.endPoint.getData('api/search',search).subscribe(resp => {
                if (resp.status && resp.message == 'success') {
                  this.places = resp.data;
                }
              });
            });
    }
    if(event.target.value.length >= 3){
      this.$searchTerm.next(event.target.value);
    }
  }

  onSelectSearch(value, key){
    let date = new Date(new Date().setDate(new Date().getDate()+4)).getTime();
    switch(key){
      case 'state':
        this.router.navigate(['/tour/tour-list/',date,value]);
      break;
      case 'location':
        this.router.navigate(['/tour/tour-list/',date,value]);
      break;
      case 'package':
        this.router.navigate(['/tour/tour-detail/',value,date, 'All']);
      break;
    }
    this.dialogRef.close();
  }

}
