export class register {
  user_name?: any;
  email?: any;
  password?: any;
}


export class login {
  email_username?: any;
  password?: any;
}

export class profileUser {
  user_name?: any
  first_name?: any;
  last_name?: any;
  email?: any;
  contact_no?: number;
  avatar?: any;
  complete?: any;
}
