import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-book-with-us',
  templateUrl: './why-book-with-us.component.html',
  styleUrls: ['./why-book-with-us.component.css']
})
export class WhyBookWithUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
