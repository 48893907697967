<h4 mat-dialog-title>Login / Sign Up</h4>
<mat-icon class="closeLogin dHide" mat-dialog-close mat-dialog-close="false">close</mat-icon>
<mat-tab-group>
    <mat-tab label="Login">
        <form name="form" (ngSubmit)="loginForm.form.valid && userLogin()" #loginForm="ngForm" novalidate>
            <mat-form-field>
              <mat-label>Email / User name</mat-label>
              <mat-icon matSuffix>mail</mat-icon>
              <input type="email"
                matInput
                name="email_username"
                [(ngModel)]="login.email_username"
                #email_username="ngModel"
                placeholder="Email / User name"
                autocomplete="off"
                required
              >
              <mat-error *ngIf="(email_username.touched || loginForm.submitted) && email_username.errors?.required">
                Email / Username is <strong>required</strong>
              </mat-error>
            </mat-form-field>

              <mat-form-field>
                <input
                [type]="hide ? 'password' : 'text'"
                matInput
                name="password"
                [(ngModel)]="login.password"
                #password="ngModel"
                placeholder="Password"
                required>
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                    <mat-error *ngIf="(password.touched || loginForm.submitted) && password.errors?.required">
                      Password is <strong>required</strong>
                    </mat-error>

              </mat-form-field>
              <!-- <span class="d-block text-right text-primary" [mat-dialog-close]="true" [routerLink]="['/pages/reset-password']"><small>Reset Password</small></span> -->
              <span class="d-block text-right text-primary" [mat-dialog-close]="true" (click)="forgetPassword()"><small>Forget Password ?</small></span>
              <div class="row mt-3">
                  <div class="col-md-12">
                    <!-- <mat-checkbox class="example-margin" aria-checked="Checked">I agree with the Privacy Policy</mat-checkbox> -->
                  </div>
              </div>
              <div class="text-center">
                <button mat-raised-button color="accent" [disabled]="!loginForm.valid && !login_loader" class="full-width">Login</button>
                <mat-progress-bar mode='indeterminate' *ngIf="login_loader" color="accent"></mat-progress-bar>
              </div>
              <div  class="text-muted text-center mt-2 mb-4"><small>or Login with</small></div>
              <div class="otherLog">
                <button mat-button class="logOptBtn" type="button" (click)="signInWithFB()">
                  <img src="assets/img/fb-icon.png">
                  <span class="logopt">Facebook</span>
                </button>
                <button mat-button class="logOptBtn" type="button" (click)="signInWithGoogle()">
                  <span><img style="width: 20px;"src="assets/img/google.svg"></span>
                  <span class="logopt">Google</span>
                </button>
              </div>
          </form>
    </mat-tab>

    <mat-tab label="Sign Up">
      <div class="py-3">
        <form name="form" (ngSubmit)="userRegisterForm.form.valid && userRegister()" #userRegisterForm="ngForm" novalidate>
          <mat-form-field>
              <span matSuffix><mat-icon>person</mat-icon></span>
                <input type="text"
                matInput
                name="user_name"
                [(ngModel)]="register.user_name"
                #user_name="ngModel"
                placeholder="User name"
                autocomplete="off"
                required>
                <mat-error *ngIf="(user_name.touched || userRegisterForm.submitted) && user_name.errors?.required">
                  User name is <strong>required</strong>
                </mat-error>
          </mat-form-field>
          <mat-form-field>
            <span matSuffix><mat-icon>email</mat-icon></span>
            <input type="text"
            matInput
            name="email"
            [(ngModel)]="register.email"
            #email="ngModel"
            placeholder="Email Id"
            autocomplete="off"
            required email>
                <mat-error *ngIf="(email.touched || userRegisterForm.submitted) && email.errors?.required">
                  Email is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="(email.touched || userRegisterForm.submitted) && email.errors?.email">
                  Please enter a valid email address
                </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
            [type]="hide ? 'password' : 'text'"
            matInput
            name="password"
            [(ngModel)]="register.password"
            #password="ngModel"
            placeholder="Password"
            required>
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
                <mat-error *ngIf="(password.touched || userRegisterForm.submitted) && password.errors?.required">
                  Password is <strong>required</strong>
                </mat-error>
          </mat-form-field>
          <div class="text-center">
            <button mat-raised-button color="accent" [disabled]="!userRegisterForm.valid && !progress_loader" class="full-width">Register</button>
            <mat-progress-bar mode='indeterminate' *ngIf="progress_loader" color="accent"></mat-progress-bar>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
