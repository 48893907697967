<div class="border-bottom searchSection" *ngIf="hideHomeModify">
  <div class="container">
     <div class="row">
        <div class="col-md-9 col-sm-12 col-12 m-auto">
           <nav class="py-3" aria-label="breadcrumb">
              <a (click)=toggleModify()>
               <div class="searchFor mHide modifyPanel align-items-center">
                  <div class="col-md-5 col-sm-5">
                     <div class="d-flex flexDirectionRC">
                        <span class="font-weight-medium text-white textS align-self-center pr-3">Destination :</span>
                        <span class="font-weight-normal text-white textS searchData w-70">{{selecedDestination}}
                        </span>
                     </div>
                  </div>
                  <div class="col-md-4 col-sm-5">
                     <div class="d-flex flexDirectionRC">
                        <span class="font-weight-medium text-white textS align-self-center pr-3">Travel Date :</span>
                        <span class="font-weight-normal text-white textS searchData">{{date | date:'EEE,
                           MMM d, y'}}</span>
                     </div>
                  </div>
                  <div class="col-md-3 col-sm-2">
                     <div class="d-flex flexDirectionRC align-self-center">
                        <div class="d-block textXS"> </div>
                        <a class="text-white" mat-button>
                           <mat-icon class="textM star">edit</mat-icon>
                           <span class="mHide">Modify</span>
                        </a>
                     </div>
                  </div>
               </div>
              </a>
              <div class="searchFor dHide align-items-center">
                 <div class="col-10 p-0">
                    <div class="align-items-center">
                       <div class="d-block font-weight-bold text-white font-size-16">{{selecedDestination}}
                       </div>
                       <span class="font-weight-normal text-white font-size-14 pr-3">{{date | date:'EEE,
                          MMMM d, y'}}</span>
                    </div>
                 </div>
                 <div class="col-2 p-0">
                    <div class="text-right align-self-center pr-2">
                       <div class="d-block font-size-12"> </div>
                       <a class="text-white mpkgEdit" (click)=toggleModify() mat-stroked-button>
                          <mat-icon class="font-size-18">edit</mat-icon>
                          <span class="mHide">Modify</span>
                       </a>
                    </div>
                 </div>
              </div>
           </nav>
        </div>
     </div>
  </div>
</div>
<div class="position-relative pt-2 pb-1" *ngIf="modifyModal">
  <div class="container">
     <mat-icon (click)=closeModify() color="warn" *ngIf="hideHomeModify" class="closeModify">cancel</mat-icon>
     <div class="card-body modifySearch">
        <div class="example-container">
          <div class="row">
            <div class="col-md-5">
              <mat-form-field appearance="" class="example-full-width pkglist" color="accent">
                <mat-label>Destination</mat-label>
                <input type="text" aria-label="Number" matInput
                  (keyup)="onStateCitySearch($event)"
                  (paste)="onStateCitySearch($event)"
                  [matAutocomplete]="location"
                  [(value)]="selecedDestination"
                  >
                  <mat-autocomplete #location="matAutocomplete">
                    <mat-optgroup label="DESTINATION" class="suggestions">
                      <mat-option *ngFor="let state of places && places.states" (onSelectionChange)="selectedDestination(state)" [value]="state" class="font-weight-bold">
                      {{state}}
                      </mat-option>
                    </mat-optgroup>

                    <mat-optgroup label="LOCATION" class="suggestions">
                      <mat-option *ngFor="let location of places.locations" (onSelectionChange)="selectedDestination(location)" [value]="location" class="font-weight-bold">
                        {{location}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                  <mat-hint>3 character to search</mat-hint>
                  <!-- <mat-error *ngIf="filterForm.controls['selecedDestination'].errors?.required">
                    Destination is Required
                  </mat-error>
                  <mat-error *ngIf="filterForm.controls['selecedDestination'].hasError('forbiddenNames')">
                    You should enter value from suggested one only.
                    <strong>'{{filterForm.controls['selecedDestination'].errors.forbiddenNames.value}}'</strong> is not
                    allowed.
                  </mat-error> -->
                  <mat-spinner *ngIf="destination_spinner" color="accent" matSuffix diameter="20"></mat-spinner>
                  <mat-icon *ngIf="!destination_spinner" matSuffix>place</mat-icon>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="" class="example-full-width pkglist traveldate" color="accent">
                <mat-label>Travel date</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly (dateChange)="searchDate($event)" [min]="minDate" [value]="tourModifySearch.searchDate">
                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <div>
                <button mat-raised-button color="accent" (click)="filterSearch()" class="searchBtn pBtn w-100 height-46"><mat-icon aria-hidden="false">search</mat-icon>Search</button>
              </div>
            </div>
            </div>
        </div>
     </div>
  </div>
</div>
