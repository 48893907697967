import { Component, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) { }

  success(message){
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['green-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  error(message){
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['red-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  info(message){
    this._snackBar.open(message, 'Cancel', {
      duration: 2000,
      panelClass: ['blue-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
