import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 300;
  topPosToStartHideing = 100;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
      document.getElementById('scrl').classList.add('scrolled');
      document.getElementById('scrl').classList.remove('scrolledT');
    } else {
      this.isShow = true;
      document.getElementById('scrl').classList.remove('scrolled');
      document.getElementById('scrl').classList.add('scrolledT');
    }

    // if (scrollPosition < this.topPosToStartShowing) {
    //   this.isShow = true;
    //   document.getElementById('scrl').classList.remove('scrolled');
    //   document.getElementById('scrl').classList.add('scrolledTop');
    // }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }

}
