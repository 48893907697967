import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { tourModifySearch } from 'src/models/modify-search-model';
import { travelDestinations, travellerDetails } from 'src/models/tour';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  destination = [1];
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
interface Count {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-plan-your-trip',
  templateUrl: './plan-your-trip.component.html',
  styleUrls: ['./plan-your-trip.component.css']
})
export class PlanYourTripComponent implements OnInit {
  tourModifySearch = new tourModifySearch();
  states = [];
  minDate = new Date(new Date().setDate(new Date().getDate()+4));
  date: any;
  destination :  travelDestinations[] = [];

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();
  constructor() { }

  counts: Count[] = [
    {value: '0', viewValue: '0'},
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'},
    {value: '4', viewValue: '4'},
    {value: '5', viewValue: '5'},
    {value: '6', viewValue: '6'},
    {value: '7', viewValue: '7'},
    {value: '8', viewValue: '8'}
  ];

  addDestinations(){
  this.destination.push(new travelDestinations());
  }

  ngOnInit(): void {
  }
  searchDate(event){
    this.date = event.target.value._d.getTime();
  }
}
