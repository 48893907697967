<main id="content">
  <div class="border-bottom mb-3 mHide">
    <div class="container">
      <nav class="py-3" aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-no-gutter mb-0 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
          <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1"><a [routerLink]="['/']">Home</a></li>
          <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1">Account</li>
          <li class="breadcrumb-item flex-shrink-0 flex-xl-shrink-1 active" aria-current="page">Reset Password</li>
        </ol>
      </nav>
    </div>
  </div>
  <section>
    <div class="container pt-5 pb-5">
      <div class="d-flex">
        <div class="col-md-8 border bg-color p-3">
          <div *ngIf="!valid_secure_key">{{reset_pasword_error_msg}}</div>
          <form name="form" (ngSubmit)="resetPasswordForm.form.valid && onResetPassword()" *ngIf="valid_secure_key;" #resetPasswordForm="ngForm"
          novalidate>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-3 col-sm-3 col-3">
              <img src="../../../assets/img/reset-password.png" class="w-100">
            </div>

              <div class="col-md-7 col-sm-9 col-9">
                <h4 mat-dialog-title>Reset Your Password</h4>
                <mat-form-field>
                  <input matInput [type]="hide ? 'password' : 'text'" name="new_password" placeholder="Enter New Password"
                    [(ngModel)]="resetPassword.new_password" #new_password="ngModel" autocomplete="off" required>
                  <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="(new_password.touched || resetPasswordForm.submitted) && new_password.errors?.required">
                    new password is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <span matSuffix>
                    <mat-icon>lock</mat-icon>
                  </span>
                  <input type="password" matInput name="confirm_password" [(ngModel)]="resetPassword.confirm_password"
                    #confirm_password="ngModel" placeholder="Confirm Password" autocomplete="off" required>
                    <mat-error
                    *ngIf="(confirm_password.touched || resetPasswordForm.submitted) && confirm_password.errors?.required">
                    confirm password is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <div class="text-center">
                    <button mat-raised-button color="accent" [disabled]="!resetPasswordForm.valid || resetBtn" class="full-width">
                    <mat-icon *ngIf="resetBtn"><mat-spinner diameter="20" color="accent" ></mat-spinner></mat-icon>Reset Password</button>
                </div>
              </div>
            <div class="col-md-1"></div>
          </div>
        </form>
        </div>
        <div class="col-md-4">
          <div class="rounded p-3 mb-5 bgc-primary">
            <h6 class="font-size-16 font-weight-bold text-white mb-3 ">Why Book With Us?</h6>
            <div class="d-flex align-items-center mb-3">
              <i class="flaticon-star font-size-25 text-white mr-3"></i>
              <h6 class="mb-0 font-size-14 text-white">
                <a href="#">No-hassle best price guarantee</a>
              </h6>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="flaticon-support font-size-25 text-white mr-3"></i>
              <h6 class="mb-0 font-size-14 text-white">
                <a href="#">Customer care available 24/7</a>
              </h6>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="flaticon-favorites-button font-size-25 text-white mr-3"></i>
              <h6 class="mb-0 font-size-14 text-white">
                <a href="#">Hand-picked Tours &amp; Activities</a>
              </h6>
            </div>
            <div class="d-flex align-items-center mb-0">
              <i class="flaticon-airplane font-size-25 text-white mr-3"></i>
              <h6 class="mb-0 font-size-14 text-white">
                <a href="#">Free Travel Insureance</a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
