import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SnackBarService } from './services/alert/snack-bar.service';
import { BehaviourService } from './services/behaviour.service';
import { EndPointService } from './services/end-point.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'tripzolo';

  constructor(
    private endpoint: EndPointService,
    public behaviourService: BehaviourService,
    public _snack_bar: SnackBarService,
    private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title
  ){
    this.resetUserCredentials();
    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                return child.snapshot.data['title'];
            } else {
                return null;
            }
        }
        return null;
    })
).subscribe( (data: any) => {
    if (data) {
        this.titleService.setTitle(data);
    }
});
  }

  onActivate(event){
    window.scroll(0,0);
  }

  ngAfterViewInit(){}

  resetUserCredentials(){
    let logged_user = JSON.parse(localStorage.getItem('user-web-login'));
    if(logged_user == null){
      this.behaviourService.setLogin(false);
      return;
    }
      this.endpoint.getData('api-user/current-user', false).subscribe(resp => {
        if (resp.status && resp.message == "success") {
          //   let update_logger = {
          //     avatar: c.avatar,
          //     contact_no: resp.data.contact_no,
          //     email: resp.data.email,
          //     first_name: resp.data.first_name,
          //     last_name: resp.data.last_name,
          //     token: resp.data.token,
          //     user_id: resp.data.user_id,
          //     user_name: resp.data.user_name,
          //     complete: resp.data.complete
          // };
          localStorage.setItem('user-web-login',JSON.stringify(resp.data));
          this.behaviourService.setLogin(resp.data);
        }
      }, err => {
        this._snack_bar.error('Oops..! Error while logged user.');
      });
  }
}
