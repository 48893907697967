<mat-icon class="closeDialog dHide" mat-dialog-close mat-dialog-close="false">close</mat-icon>
<div class="row forgetPassword">
  <div class="col-md-5">
    <img src="../../../assets/img/forget-password.png">
  </div>
  <div class="col-md-7">
    <h4 mat-dialog-title class="mb-0">Forget Password ?</h4>
    <p class="textS">Change your password in three easy steps.</p>
    <ol class="list-unstyled">
      <li class="textS"><span class="text-primary textS">1. </span>Fill in your email address below.</li>
      <li class="textS"><span class="text-primary textS">2. </span>We'll email you a link.</li>
      <li class="textS"><span class="text-primary textS">3. </span>Use the link to change your password on our secure
        website.</li>
    </ol>
    <form name="form" (ngSubmit)="forgotPasswordForm.form.valid && sendForgotPasswordMail()" #forgotPasswordForm="ngForm" novalidate>
    <mat-form-field>
      <span matSuffix>
        <mat-icon>email</mat-icon>
      </span>
      <input type="email"
      matInput
      name="email"
      #email="ngModel"
      [(ngModel)]="forgot_password.email"
      placeholder="Enter Verified Email Id"
      autocomplete="off"
      required
      email
      >
      <mat-hint>Type in the email address you used when you registered.</mat-hint>
      <mat-error *ngIf="(email.touched || forgotPasswordForm.submitted) && email.errors?.required">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="email.touched && email.errors?.email">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <div class="text-center mt-3">
      <button mat-raised-button color="accent" [disabled]="!forgotPasswordForm.valid" class="full-width">Submit</button>
    </div>
   </form>
  </div>
</div>
