export class travellerDetails {
  noOfTravellers?: number;
  adults? = [];
  childrens? = [];
  childOne? = [];
  childTwo? = []
}
export class travelDestinations {
  destinationname: string;

}

export class customizePackage{
  full_name?: any;
  email?: any;
  contact_no?: any;
  travel_date?: any;
  rooms?: number;
  adults?: number;
  children?: number;
  message?: any;
  package_id: number
}

export class callBack{
  full_name?: any;
  email?: any;
  contact_no?: any;
  message?: any;
  // package_id: number
}
